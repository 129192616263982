import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { getUserProfile, getSuggestedQuestions } from '../services/endpoints';
import UploadModal from './UploadModal';
import LeftMenu from './LeftMenu';
import Query from './Query';
import ChatSidebar from './ChatSidebar';
import FileSearch from './FileSearch';
import '../styles/Dashboard.css';
import '../styles/DashboardMobile.css';
import { createChat } from '../services/endpoints';
import { trackEvent } from '../utils/analytics';

const useViewport = () => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  return { width };
};

const Dashboard = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { width } = useViewport();
  const isMobile = width <= 768;

  useEffect(() => {
    if (location.pathname === '/' && !isMobile) {
      navigate('/dashboard', { replace: true });
    }
  }, [isMobile, navigate, location]);

  const [profile, setProfile] = useState({
    first_name: '',
    last_name: '',
    email: '',
    goffer_email: ''
  });
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [showChat, setShowChat] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState('');
  const [isChatSidebarOpen, setIsChatSidebarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [chatId, setChatId] = useState(null);
  const [initialResponse, setInitialResponse] = useState(null);
  const [selectedChat, setSelectedChat] = useState(null);
  const [isFileSearchOpen, setIsFileSearchOpen] = useState(false);
  const fileSearchRef = useRef(null);
  const inputRef = useRef(null);
  const [suggestedQuestions, setSuggestedQuestions] = useState({
    "Time-Based": [],
    "Action-Based": [],
    "Domain-Based": []
  });
  const [isLoadingQuestions, setIsLoadingQuestions] = useState(false);

  const suggestionTemplates = useMemo(() => [
    {
      title: "Time-Based",
      examples: [
        "When was this document created?",
        "What are the key deadlines mentioned?",
        "How long is this agreement valid for?",
        "What is the timeline for this project?",
        "When do these requirements need to be met?"
      ]
    },
    {
      title: "Action-Based",
      examples: [
        "What steps are required for compliance?",
        "How should I proceed with this process?",
        "What actions need to be taken?",
        "What is the procedure outlined here?",
        "What decisions need to be made?"
      ]
    },
    {
      title: "Domain-Based",
      examples: [
        "What technical terms are used here?",
        "Who are the key stakeholders involved?",
        "What industry standards are referenced?",
        "What are the main concepts discussed?",
        "What specific requirements are mentioned?"
      ]
    }
  ], []);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await getUserProfile();
        setProfile(response.data);
      } catch (error) {
        console.error('Error fetching profile:', error);
      }
    };

    fetchProfile();
  }, []);

  useEffect(() => {
    const resetChat = () => {
      setShowChat(false);
      setCurrentQuestion('');
      setChatId(null);
      setInitialResponse(null);
      setSelectedChat(null);
    };

    resetChat();
  }, []);

  useEffect(() => {
    console.log('isChatSidebarOpen:', isChatSidebarOpen);
  }, [isChatSidebarOpen]);

  useEffect(() => {
    const fetchSuggestedQuestions = async () => {
      try {
        setIsLoadingQuestions(true);
        const response = await getSuggestedQuestions();
        if (response.data.success) {
          setSuggestedQuestions(response.data.data);
        }
      } catch (error) {
        console.error('Error fetching suggested questions:', error);
        // Fall back to default templates if there's an error
        setSuggestedQuestions(suggestionTemplates.reduce((acc, section) => {
          acc[section.title] = section.examples;
          return acc;
        }, {}));
      } finally {
        setIsLoadingQuestions(false);
      }
    };

    fetchSuggestedQuestions();
  }, [suggestionTemplates]);

  const handleQuestionSubmit = async (question) => {
    if (!question.trim()) return;
    
    try {
      setIsLoading(true);
      const chatPayload = {
        content: question,
        sender: 'user',
        files: []
      };
      const result = await createChat(chatPayload);
      
      setShowChat(true);
      setChatId(result.chat.id);
      setInitialResponse(result.initialResponse);
      trackEvent('query_submitted', { 
        query_length: question.length,
        query_type: 'chat'
      });
    } catch (error) {
      console.error('Error creating chat:', error);
      trackEvent('query_error', {
        error_type: error.response?.data?.error || 'unknown_error'
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleChatSelect = (chat) => {
    setShowChat(true);
    setChatId(chat.id);
    setSelectedChat(chat);
  };

  const handleFileSearchClick = () => {
    if (isFileSearchOpen) {
      fileSearchRef.current?.setIsFilterPanelOpen(true);
      return;
    }
    
    setIsFileSearchOpen(true);
  };

  const handleChatHistoryClick = () => {
    console.log('Opening chat history, current state:', !isChatSidebarOpen);
    setIsChatSidebarOpen(!isChatSidebarOpen);
  };

  const handleTemplateClick = (templateText) => {
    setCurrentQuestion(templateText);
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleFileUpload = async (files) => {
    try {
      setIsUploadModalOpen(true);
      trackEvent('document_upload', { 
        file_count: files.length,
        file_types: files.map(f => f.type)
      });
    } catch (error) {
      trackEvent('document_upload_error', {
        error_type: error.response?.data?.error || 'unknown_error'
      });
    }
  };

  return (
    <div className="dashboard">
      <LeftMenu 
        onChatHistoryClick={handleChatHistoryClick}
        onFileSearchClick={handleFileSearchClick}
        onFileUpload={handleFileUpload}
        isFileSearchActive={isFileSearchOpen}
      />
      <div className="dashboard-content" style={{ marginLeft: "120px" }}>
        <div className="content-wrapper">
          <div className="main-content">
            <FileSearch 
              ref={fileSearchRef}
              isOpen={isFileSearchOpen}
              onClose={() => setIsFileSearchOpen(false)}
              style={{ display: isFileSearchOpen ? 'block' : 'none' }}
            />
            
            {!isFileSearchOpen && !showChat && (
              <>
                <h1 className="welcome-text">Welcome back {profile.first_name},</h1>
                <div className="chat-input-container">
                  <input
                    ref={inputRef}
                    type="text"
                    className="chat-input"
                    placeholder="Ask a question about your documents"
                    value={currentQuestion}
                    onChange={(e) => setCurrentQuestion(e.target.value)}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter' && currentQuestion.trim()) {
                        handleQuestionSubmit(currentQuestion);
                      }
                    }}
                  />
                  <button 
                    className="chat-button"
                    onClick={() => handleQuestionSubmit(currentQuestion)}
                    disabled={!currentQuestion.trim() || isLoading}
                  >
                    {isLoading ? (
                      <i className="fas fa-spinner fa-spin"></i>
                    ) : (
                      <i className="fas fa-paper-plane"></i>
                    )}{' '}
                    Ask AI
                  </button>
                </div>

                <div className="templates-grid">
                  {isLoadingQuestions ? (
                    <div className="loading-questions">
                      <i className="fas fa-spinner fa-spin"></i> Loading suggested questions...
                    </div>
                  ) : (
                    Object.entries(suggestedQuestions).map(([title, examples], index) => (
                      <div key={index} className="template-section">
                        <h2>{title}</h2>
                        <div className="template-items">
                          {examples.map((example, exampleIndex) => (
                            <button 
                              key={exampleIndex}
                              onClick={() => handleTemplateClick(example)}
                            >
                              {example}
                            </button>
                          ))}
                        </div>
                      </div>
                    ))
                  )}
                </div>
              </>
            )}
            
            {!isFileSearchOpen && showChat && (
              <Query 
                chatId={selectedChat ? selectedChat.id : chatId}
                initialQuestion={currentQuestion}
                initialResponse={initialResponse}
                existingChat={selectedChat}
                key={selectedChat ? selectedChat.id : chatId}
              />
            )}
          </div>
        </div>
      </div>
      <ChatSidebar 
        isOpen={isChatSidebarOpen} 
        onClose={() => setIsChatSidebarOpen(false)} 
        onChatSelect={handleChatSelect}
        onFileSearchClose={() => setIsFileSearchOpen(false)}
      />
      <UploadModal 
        isOpen={isUploadModalOpen} 
        onClose={() => setIsUploadModalOpen(false)} 
      />
    </div>
  );
};

export default Dashboard;
