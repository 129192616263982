import React, { useState } from 'react';
import '../../../styles/mobile/chat-input.scss';

const ChatInputBar = ({ onSubmit, isLoading }) => {
  const [message, setMessage] = useState('');

  const handleTextSubmit = () => {
    if (isLoading || !message.trim()) {
      return;
    }
    
    onSubmit({ 
      type: 'text', 
      content: message 
    });
    
    setMessage('');
    
    // Reset textarea height to original
    const textarea = document.querySelector('.text-row textarea');
    if (textarea) {
      textarea.style.height = '44px';
    }
  };

  const handleAttachFiles = (event) => {
    const files = Array.from(event.target.files);
    if (files.length === 0) return;

    files.forEach(file => {
      onSubmit({
        type: file.type.startsWith('image/') ? 'image' : 'file',
        content: '',
        file: file
      });
    });

    event.target.value = null; // Reset file input
  };

  return (
    <div className="chat-input-bar">
      <div className="input-container">
        <input 
          type="file" 
          id="fileInput" 
          multiple 
          style={{ display: 'none' }} 
          onChange={handleAttachFiles} 
        />

        <div className="text-row">
          <textarea 
            placeholder="Message Goffer AI..."
            value={message}
            onChange={(e) => {
              setMessage(e.target.value);
              e.target.style.height = 'auto';
              e.target.style.height = e.target.scrollHeight + 'px';
            }}
            onKeyPress={(e) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault();
                handleTextSubmit();
              }
            }}
            rows="1"
          />
        </div>

        <div className="actions">
          <div className="left-actions">
            <button 
              className="action-button"
              onClick={() => document.getElementById('fileInput').click()}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <path d="M12 4v16m-8-8h16" stroke="currentColor" strokeWidth="2" fill="none"/>
              </svg>
            </button>

            <button className="action-button">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-1 17.93c-3.95-.49-7-3.85-7-7.93 0-.62.08-1.21.21-1.79L9 15v1c0 1.1.9 2 2 2v1.93zm6.9-2.54c-.26-.81-1-1.39-1.9-1.39h-1v-3c0-.55-.45-1-1-1H8v-2h2c.55 0 1-.45 1-1V7h2c1.1 0 2-.9 2-2v-.41c2.93 1.19 5 4.06 5 7.41 0 2.08-.8 3.97-2.1 5.39z" fill="currentColor"/>
              </svg>
            </button>
          </div>

          <div className="right-actions">
            <button className="action-button">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <path d="M12 14c1.66 0 3-1.34 3-3V5c0-1.66-1.34-3-3-3S9 3.34 9 5v6c0 1.66 1.34 3 3 3z" fill="currentColor"/>
                <path d="M17 11c0 2.76-2.24 5-5 5s-5-2.24-5-5H5c0 3.53 2.61 6.43 6 6.92V21h2v-3.08c3.39-.49 6-3.39 6-6.92h-2z" fill="currentColor"/>
              </svg>
            </button>

            <button 
              className="action-button send-button" 
              onClick={handleTextSubmit}
              disabled={isLoading || !message.trim()}
            >
              {isLoading ? (
                <svg className="spinner" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                  <path d="M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm0 18a8 8 0 1 1 8-8 8 8 0 0 1-8 8z" opacity=".3"/>
                  <path d="M20 12h2A10 10 0 0 0 12 2v2a8 8 0 0 1 8 8z"/>
                </svg>
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                  <path d="M12 4l0 12M12 4l-4 4M12 4l4 4" stroke="currentColor" strokeWidth="2" fill="none"/>
                </svg>
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatInputBar; 