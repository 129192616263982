import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MobileNavigation from '../navigation/MobileNavigation';
import MobileMenu from './MobileMenu';
import '../../../styles/mobile/layout.scss';

const MobileLayout = ({ children, hideNavigation }) => {
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleNewChat = () => {
    navigate('/m/');
  };

  return (
    <div className="mobile-layout">
      <div className="mobile-header">
        <button className="menu-button" onClick={() => setIsMenuOpen(true)}>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"/>
          </svg>
        </button>
        <h1 className="app-title">goffer.ai</h1>
        <button className="new-chat-button" onClick={handleNewChat}>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"/>
          </svg>
        </button>
      </div>
      <div className="mobile-content">
        {children}
      </div>
      {!hideNavigation && <MobileNavigation />}
      <MobileMenu 
        isOpen={isMenuOpen} 
        onClose={() => setIsMenuOpen(false)} 
      />
    </div>
  );
};

export default MobileLayout; 