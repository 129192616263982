import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import '../../../styles/mobile/navigation.scss';

const MobileNavigation = ({ isOpen, onClose }) => {
  const location = useLocation();

  const tabs = [
    { id: 'chat', label: 'Chat History', path: '/m/chat' },
    { id: 'search', label: 'Search', path: '/m/search' },
    { id: 'reminders', label: 'Reminders', path: '/m/reminders' },
    { id: 'profile', label: 'Profile', path: '/m/profile' }
  ];

  if (!isOpen) return null;

  return (
    <div className="mobile-menu-overlay" onClick={onClose}>
      <nav className="mobile-navigation" onClick={e => e.stopPropagation()}>
        {tabs.map(tab => (
          <Link
            key={tab.id}
            to={tab.path}
            className={`nav-item ${location.pathname === tab.path ? 'active' : ''}`}
            onClick={onClose}
          >
            <span>{tab.label}</span>
          </Link>
        ))}
      </nav>
    </div>
  );
};

export default MobileNavigation; 