import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/LeftMenu.css';

const LeftMenu = ({ onChatHistoryClick, onFileSearchClick, isFileSearchActive }) => {
  const navigate = useNavigate();

  const handleDashboardClick = (e) => {
    e.preventDefault();
    navigate('/dashboard', { replace: true });
    window.location.reload();
  };

  const handleChatHistoryClick = () => {
    console.log('Chat history clicked');
    onChatHistoryClick();
  };

  const handleFileSearchClick = () => {
    console.log('File search clicked');
    onFileSearchClick();
  };

  const handleRemindersClick = () => {
    navigate('/reminders');
  };

  return (
    <div className="left-menu">
      <button 
        onClick={handleDashboardClick}
        className="menu-item"
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="white" viewBox="0 0 256 256">
          <path d="M218.83,103.77l-80-75.48a1.14,1.14,0,0,1-.11-.11,16,16,0,0,0-21.53,0l-.11.11L37.17,103.77A16,16,0,0,0,32,115.55V208a16,16,0,0,0,16,16H96a16,16,0,0,0,16-16V160h32v48a16,16,0,0,0,16,16h48a16,16,0,0,0,16-16V115.55A16,16,0,0,0,218.83,103.77ZM208,208H160V160a16,16,0,0,0-16-16H112a16,16,0,0,0-16,16v48H48V115.55l.11-.1L128,40l79.9,75.43.11.1Z"/>
        </svg>
      </button>
      
      <button 
        onClick={handleChatHistoryClick}
        className="menu-item"
        title="Chat History"
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="white" viewBox="0 0 256 256">
          <path d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Zm64-88a8,8,0,0,1-8,8H128a8,8,0,0,1-8-8V72a8,8,0,0,1,16,0v72h56A8,8,0,0,1,192,128Z"/>
        </svg>
      </button>

      <button 
        onClick={handleFileSearchClick}
        className="menu-item"
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="white" viewBox="0 0 256 256">
          <path d="M232.49,215.51,185,168a92.12,92.12,0,1,0-17,17l47.53,47.54a12,12,0,0,0,17-17ZM44,112a68,68,0,1,1,68,68A68.07,68.07,0,0,1,44,112Z"/>
        </svg>
      </button>

      <button 
        onClick={handleRemindersClick}
        className="menu-item relative"
        title="Reminder Settings"
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="white" viewBox="0 0 16 16">
          <path d="M8 2.5a4.5 4.5 0 0 0-4.5 4.5v3.5c0 .5-.1 1-.3 1.5h9.6c-.2-.5-.3-1-.3-1.5V7A4.5 4.5 0 0 0 8 2.5zM6.5 13a1.5 1.5 0 0 0 3 0h-3z"/>
        </svg>
      </button>
    </div>
  );
};

export default LeftMenu; 