import React, { useState, useEffect, useRef } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import MobileLayout from '../layout/MobileLayout';
import ChatInputBar from '../shared/ChatInputBar';
import { getChatMessages, queryChatMessage } from '../../../services/endpoints';
import { processMessage } from '../../../utils/messageHandlers';
import '../../../styles/mobile/chat.scss';

// =====================================================
// WARNING: DO NOT REMOVE ANY OF THESE AI COMMENTS!
// These comments are critical for AI understanding and maintenance
// Removing them will break AI functionality and file upload capabilities
// =====================================================

// Main component for mobile chat interface
// Handles real-time messaging, file uploads, and chat history
// Integrates with ChatInputBar for file attachments and photo capture
const MobileChat = () => {
  // Extract chat ID from URL parameters
  const { chatId } = useParams();
  const location = useLocation();
  
  // ===== STATE MANAGEMENT =====
  // Controls mobile menu visibility
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  // Stores the chat message history including text, images and files
  const [chatHistory, setChatHistory] = useState([]);
  // Loading state for API calls and file uploads
  const [isLoading, setIsLoading] = useState(false);
  // Error state management for API and upload failures
  const [error, setError] = useState(null);

  // ===== REFS =====
  // Reference for auto-scrolling to bottom of chat
  const messagesEndRef = useRef(null);
  // Prevents duplicate processing of initial state
  // CRITICAL: Do not modify this ref handling
  const initialStateProcessed = useRef(false);

  // ===== INITIAL STATE PROCESSING =====
  // CRITICAL: This effect handles the initial chat state from navigation
  // Processes both single messages and message arrays
  // DO NOT MODIFY without understanding the message structure
  useEffect(() => {
    if (!initialStateProcessed.current && location.state) {
      console.log('=== Processing initial chat state ===');
      const { initialMessage, initialResponse } = location.state;
      console.log('Initial state received:', { initialMessage, initialResponse });
      
      if (initialMessage) {
        const messages = [];
        
        // IMPORTANT: Handle both array and single message formats
        // This supports both new and legacy message structures
        if (Array.isArray(initialMessage)) {
          console.log('Processing initial messages array:', initialMessage);
          initialMessage.forEach(msg => {
            messages.push({
              sender: 'You',
              content: msg.content,
              type: msg.type,
              file: msg.file // Contains file metadata for uploads
            });
          });
        } else {
          // Legacy format support - DO NOT REMOVE
          // Required for backward compatibility
          console.log('Processing single initial message:', initialMessage);
          messages.push({
            sender: 'You',
            content: initialMessage.content,
            type: initialMessage.type,
            file: initialMessage.file
          });
        }

        // Process AI response if available
        // Handles both answer and message response formats
        if (initialResponse) {
          console.log('Adding AI response:', initialResponse);
          messages.push({
            sender: 'AI',
            content: initialResponse.content || initialResponse.answer,
            type: initialResponse.type || 'text',
            file: initialResponse.file
          });
        }

        console.log('Setting chat history with messages:', messages);
        setChatHistory(messages);
        initialStateProcessed.current = true;
      }
    }
  }, [location.state]);

  // ===== CHAT HISTORY LOADING =====
  // Fetches existing chat history from backend
  // Includes text messages, images, and file attachments
  const loadChatHistory = React.useCallback(async () => {
    if (!chatId || initialStateProcessed.current) return;  // Skip if initial state exists

    try {
      const response = await getChatMessages(chatId);
      const history = response.data.messages.map(msg => ({
        sender: msg.sender === 'user' ? 'You' : 'AI',
        content: msg.content,
        type: msg.type || 'text',
        file: msg.file || null
      }));
      setChatHistory(history);
    } catch (error) {
      console.error('Error loading chat history:', error);
    }
  }, [chatId]);

  // Load chat history on component mount and when chatId changes
  useEffect(() => {
    loadChatHistory();
    initialStateProcessed.current = false; // Reset the flag when chatId changes
  }, [loadChatHistory, chatId]); // Added chatId as dependency

  // Auto-scroll to bottom when new messages arrive
  useEffect(() => {
    scrollToBottom();
  }, [chatHistory]);

  // ===== UTILITY FUNCTIONS =====
  // Smooth scroll to bottom of chat
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  // ===== MESSAGE HANDLING =====
  // CRITICAL: Main message submission handler
  // Processes text messages, images, and file uploads
  // Integrates with ChatInputBar component for file handling
  const handleSubmit = async (messageData) => {
    if (isLoading) return;
    if (!chatId) {
      setError('Cannot submit message: No chatId provided');
      return;
    }

    console.log('=== MobileChat handleSubmit ===');
    console.log('ChatId:', chatId);
    console.log('Received message data:', messageData);

    try {
      setIsLoading(true);
      setError(null);

      // Process message and handle file uploads
      // Supports images and document attachments
      const { message, files } = await processMessage(messageData);
      
      // Immediately show user message for better UX
      setChatHistory(prev => [...prev, {
        sender: 'You',
        ...message
      }]);

      // Send message to AI for processing
      // Includes file metadata for uploaded content
      const response = await queryChatMessage(
        chatId, 
        message.content,
        { 
          files: files.map(file => ({
            s3_url: file.s3_url,
            filename: file.filename,
            file_type: file.file_type,
            id: file.id
          }))
        }
      );

      // Handle AI response
      // Processes both text and file responses
      if (response.data) {
        const aiMessage = {
          sender: 'AI',
          content: response.data.answer || response.data.message || 'I received your message.',
          type: response.data.type || 'text',
          file: response.data.file
        };
        setChatHistory(prev => [...prev, aiMessage]);
      }
    } catch (error) {
      console.error('Error processing message:', error);
      setError(error.message || 'An error occurred while processing your message.');
      setChatHistory(prev => [...prev, {
        sender: 'AI',
        content: 'Sorry, an error occurred while processing your message.',
        type: 'error'
      }]);
    } finally {
      setIsLoading(false);
    }
  };

  // Add a retry function
  const retryLoadChat = () => {
    setError(null);
    loadChatHistory();
  };

  // Display error message if there is one
  if (error) {
    return (
      <MobileLayout 
        onMenuToggle={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
        title="Chat"
      >
        <div className="error-container">
          <div className="error-content">
            <p className="error-message">
              {error}
            </p>
            <button 
              className="retry-button"
              onClick={retryLoadChat}
            >
              Try Again
            </button>
          </div>
        </div>
      </MobileLayout>
    );
  }

  // ===== RENDER COMPONENT =====
  // Renders chat interface with messages and input bar
  return (
    <MobileLayout 
      onMenuToggle={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
      title="Chat"
    >
      <div className="mobile-chat">
        <div className="chat-messages">
          {chatHistory.map((message, index) => (
            <div 
              key={index} 
              className={`message ${message.sender === 'You' ? 'user' : 'ai'}`}
            >
              <div className="message-content">
                {message.type === 'image' ? (
                  <div className="message-image-container">
                    <img 
                      src={message.file?.presigned_url || message.file?.s3_url} 
                      alt=""
                      className="message-image"
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.outerHTML = `<div class="placeholder-image">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" style="width: 48px; height: 48px; opacity: 0.5;">
                            <path d="M21 19V5c0-1.1-.9-2-2-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2zM8.5 13.5l2.5 3.01L14.5 12l4.5 6H5l3.5-4.5z"/>
                          </svg>
                          <div style="margin-top: 8px; font-size: 14px; opacity: 0.7;">Image not available</div>
                        </div>`;
                      }}
                    />
                    {message.content && message.content !== 'Uploaded image' && (
                      <div className="message-text">{message.content}</div>
                    )}
                  </div>
                ) : message.type === 'file' ? (
                  <div className="message-file">
                    <div className="file-preview">
                      <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="currentColor">
                        <path d="M14 2H6c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zm2 16H8v-2h8v2zm0-4H8v-2h8v2zm-3-5V3.5L18.5 9H13z"/>
                      </svg>
                    </div>
                    <div className="file-info">
                      <span className="filename">{message.file?.filename}</span>
                      {message.content && <span className="message-text">{message.content}</span>}
                    </div>
                  </div>
                ) : (
                  <span 
                    className="formatted-message"
                    dangerouslySetInnerHTML={{ 
                      __html: message.content.replace(/\n/g, '<br>').replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>') 
                    }} 
                  />
                )}
              </div>
            </div>
          ))}
          <div ref={messagesEndRef} />
        </div>

        <ChatInputBar onSubmit={handleSubmit} isLoading={isLoading} />
      </div>
    </MobileLayout>
  );
};

export default MobileChat; 