import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// Initialize Google Analytics
export const initGA = () => {
    const script = document.createElement('script');
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GA_MEASUREMENT_ID}`;
    document.head.appendChild(script);

    // Define gtag function before loading the script
    window.dataLayer = window.dataLayer || [];
    window.gtag = function() {
        window.dataLayer.push(arguments);
    }
    window.gtag('js', new Date());
    window.gtag('config', process.env.REACT_APP_GA_MEASUREMENT_ID, {
        send_page_view: true,
        cookie_flags: 'SameSite=None;Secure'
    });
};

// Custom hook to track page views
export const usePageTracking = () => {
    const location = useLocation();

    useEffect(() => {
        if (typeof window.gtag !== 'undefined' && process.env.REACT_APP_GA_MEASUREMENT_ID) {
            window.gtag('event', 'page_view', {
                page_path: location.pathname + location.search,
                page_location: window.location.href,
                page_title: document.title
            });
        }
    }, [location]);
};

// Track custom events
export const trackEvent = (eventName, eventParams = {}) => {
    if (typeof window.gtag !== 'undefined' && process.env.REACT_APP_GA_MEASUREMENT_ID) {
        window.gtag('event', eventName, {
            ...eventParams,
            page_path: window.location.pathname + window.location.search,
            page_location: window.location.href
        });
    }
}; 