import React from 'react';
import DOMPurify from 'dompurify';

const ChatMessage = ({ message, onCopy, isCopied }) => {
  const isUser = message.sender === 'user';
  
  return (
    <div className={`message ${isUser ? 'user' : 'ai'}`}>
      {message.type === 'image' ? (
        <div className="message-image-container">
          <img 
            src={message.file?.presigned_url || message.file?.s3_url} 
            alt=""
            className="message-image"
            onError={(e) => {
              e.target.onerror = null;
              e.target.outerHTML = `<div class="placeholder-image">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" style="width: 48px; height: 48px; opacity: 0.5;">
                  <path d="M21 19V5c0-1.1-.9-2-2-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2zM8.5 13.5l2.5 3.01L14.5 12l4.5 6H5l3.5-4.5z"/>
                </svg>
                <div style="margin-top: 8px; font-size: 14px; opacity: 0.7;">Image not available</div>
              </div>`;
            }}
          />
          {message.content && message.content !== 'Uploaded image' && (
            <div className="message-text">{message.content}</div>
          )}
        </div>
      ) : message.type === 'file' ? (
        <div className="message-file">
          <div className="file-preview">
            <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="currentColor">
              <path d="M14 2H6c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zm2 16H8v-2h8v2zm0-4H8v-2h8v2zm-3-5V3.5L18.5 9H13z"/>
            </svg>
          </div>
          <div className="file-info">
            <span className="filename">{message.file?.filename}</span>
            {message.content && <span className="message-text">{message.content}</span>}
          </div>
        </div>
      ) : (
        <div className="message-content">
          <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(message.content) }} />
        </div>
      )}
      <button 
        className="copy-button" 
        onClick={() => onCopy(message.content)}
        title="Copy to clipboard"
      >
        <i className={`fas ${isCopied ? 'fa-check text-success' : 'fa-copy'}`}></i>
      </button>
    </div>
  );
};

export default ChatMessage; 