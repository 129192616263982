import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import heroImage from '../images/hero.webp';
import { Helmet } from 'react-helmet-async';

function Home() {
  const navigate = useNavigate();

  const handleTryItNow = () => {
    navigate('/login');
  };

  return (
    <>
      <Helmet>
        <title>Goffer.AI - Your Intelligent Document Assistant</title>
        <meta name="description" content="Goffer.AI helps you manage documents, extract important dates, and never miss deadlines. Smart document processing with AI-powered date detection and intelligent alerts." />
        <meta name="keywords" content="AI assistant, document management, smart alerts, deadline tracking, OCR, PDF processing, email management, personal assistant" />
        
        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Goffer.AI - Your Intelligent Document Assistant" />
        <meta property="og:description" content="Never miss a deadline again. Goffer.AI automatically processes your documents, extracts important dates, and sends smart reminders." />
        <meta property="og:image" content="https://goffer.ai/og-image.jpg" />
        
        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Goffer.AI - Your Intelligent Document Assistant" />
        <meta name="twitter:description" content="Never miss a deadline again. Goffer.AI automatically processes your documents, extracts important dates, and sends smart reminders." />
        <meta name="twitter:image" content="https://goffer.ai/twitter-card.jpg" />
        
        {/* Additional SEO */}
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Goffer.AI Team" />
        <link rel="canonical" href="https://goffer.ai" />
        
        {/* Structured Data for Google */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "SoftwareApplication",
            "name": "Goffer.AI",
            "applicationCategory": "BusinessApplication",
            "description": "AI-powered document assistant that helps you manage documents, extract important dates, and never miss deadlines.",
            "operatingSystem": "Web",
            "offers": {
              "@type": "Offer",
              "price": "0",
              "priceCurrency": "USD"
            }
          })}
        </script>
      </Helmet>

      <div className="relative flex size-full min-h-screen flex-col bg-white group/design-root overflow-x-hidden z-0" style={{fontFamily: 'Inter, "Noto Sans", sans-serif'}}>
        <div className="layout-container flex h-full grow flex-col">
          <div className="px-4 md:px-10 lg:px-40 flex flex-1 justify-center py-5">
            <div className="layout-content-container flex flex-col max-w-[960px] flex-1">
              {/* Hero Section */}
              <div className="@container">
                <div className="@[480px]:p-4">
                  <div
                    className="flex min-h-[300px] md:min-h-[480px] flex-col gap-4 md:gap-6 bg-cover bg-center bg-no-repeat md:gap-8 rounded-xl items-start justify-end px-4 pb-6 md:pb-10 md:px-10"
                    style={{
                      backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.4) 100%), url(${heroImage})`
                    }}
                  >
                    <div className="flex flex-col gap-2 text-left">
                      <h1 className="text-white text-2xl md:text-4xl font-black leading-tight tracking-[-0.033em] md:text-5xl md:font-black md:leading-tight md:tracking-[-0.033em]">
                      Find Documents Instantly. Uncover Insights Automatically.
                      </h1>
                      <h2 className="text-white text-sm font-normal leading-normal @[480px]:text-base @[480px]:font-normal @[480px]:leading-normal">
                      From "where's my receipt?" to "how much did I spend last quarter?" - just ask, and Goffer.ai delivers.
                      </h2>
                    </div>
                    <div className="flex-wrap gap-3 flex">
                      <button 
                        onClick={handleTryItNow}
                        className="flex min-w-[84px] w-full md:w-auto max-w-[480px] cursor-pointer items-center justify-center overflow-hidden rounded-xl h-10 px-4 md:h-12 md:px-5 bg-[#1980e6] text-white text-sm font-bold leading-normal tracking-[0.015em] md:text-base md:font-bold md:leading-normal md:tracking-[0.015em]"
                      >
                        <span className="truncate">Try Goffer.ai Free</span>
                      </button>
                      <Link 
                        to="/features"
                        className="flex min-w-[84px] max-w-[480px] cursor-pointer items-center justify-center overflow-hidden rounded-xl h-10 px-4 @[480px]:h-12 @[480px]:px-5 bg-[#f0f2f4] text-[#111418] text-sm font-bold leading-normal tracking-[0.015em] @[480px]:text-base @[480px]:font-bold @[480px]:leading-normal @[480px]:tracking-[0.015em]"
                      >
                        <span className="truncate">Learn more</span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

              {/* What you can do section */}
              <div className="flex flex-col gap-10 px-4 py-10 @container">
                <div className="flex flex-col gap-4">
                  <h1 className="text-[#111418] tracking-light text-[32px] font-bold leading-tight @[480px]:text-4xl @[480px]:font-black @[480px]:leading-tight @[480px]:tracking-[-0.033em] max-w-[720px]">
                    What you can do with Goffer AI?
                  </h1>
                  <p className="text-[#111418] text-base font-normal leading-normal max-w-[720px]">
                    Goffer AI provides powerful AI capabilities for document processing, management, and insights. Use these capabilities to streamline business processes, improve decision-making, and unlock new opportunities.
                  </p>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3">
                  {/* Document Cards */}
                  <div className="flex flex-col gap-3 pb-3">
                    <div
                      className="w-full bg-center bg-no-repeat aspect-video bg-cover rounded-xl"
                      style={{
                        backgroundImage: 'url("https://cdn.usegalileo.ai/sdxl10/170ed825-9f3b-457c-a2a4-419d79dddd82.png")'
                      }}
                    >
                    </div>
                    <div>
                      <p className="text-[#111418] text-base font-medium leading-normal">Document understanding</p>
                      <p className="text-[#637588] text-sm font-normal leading-normal">Understand and extract information from documents</p>
                    </div>
                  </div>
                  <div className="flex flex-col gap-3 pb-3">
                    <div
                      className="w-full bg-center bg-no-repeat aspect-video bg-cover rounded-xl"
                      style={{
                        backgroundImage: 'url("https://cdn.usegalileo.ai/sdxl10/f4a79772-f37b-47fb-be46-3efff2105879.png")'
                      }}
                    >
                    </div>
                    <div>
                      <p className="text-[#111418] text-base font-medium leading-normal">Document organization</p>
                      <p className="text-[#637588] text-sm font-normal leading-normal">Organize and manage your documents</p>
                    </div>
                  </div>
                  <div className="flex flex-col gap-3 pb-3">
                    <div
                      className="w-full bg-center bg-no-repeat aspect-video bg-cover rounded-xl"
                      style={{
                        backgroundImage: 'url("https://cdn.usegalileo.ai/sdxl10/7763bc2d-e346-4bab-a797-6fe2ca30232a.png")'
                      }}
                    >
                    </div>
                    <div>
                      <p className="text-[#111418] text-base font-medium leading-normal">Document insights</p>
                      <p className="text-[#637588] text-sm font-normal leading-normal">Extract insights and automate tasks with AI</p>
                    </div>
                  </div>
                </div>
              </div>

              {/* Interactive Examples section */}
              <div className="flex flex-col gap-10 px-4 py-10 @container">
                <div className="flex flex-col gap-4">
                  <h1 className="text-[#111418] tracking-light text-[32px] font-bold leading-tight @[480px]:text-4xl @[480px]:font-black @[480px]:leading-tight @[480px]:tracking-[-0.033em] max-w-[720px]">
                    Interactive Examples
                  </h1>
                  <p className="text-[#111418] text-base font-normal leading-normal max-w-[720px]">
                    Explore the interactive examples showcasing our service's capabilities
                  </p>
                </div>
                <div className="grid grid-cols-[repeat(auto-fit,minmax(158px,1fr))] gap-3 p-0">
                  <div className="flex flex-1 gap-3 rounded-lg border border-[#dce0e5] bg-white p-4 flex-col">
                    <div className="text-[#111418]" data-icon="Calendar" data-size="24px" data-weight="regular">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 256 256">
                        <path d="M208,32H184V24a8,8,0,0,0-16,0v8H88V24a8,8,0,0,0-16,0v8H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32ZM72,48v8a8,8,0,0,0,16,0V48h80v8a8,8,0,0,0,16,0V48h24V80H48V48ZM208,208H48V96H208V208Zm-96-88v64a8,8,0,0,1-16,0V132.94l-4.42,2.22a8,8,0,0,1-7.16-14.32l16-8A8,8,0,0,1,112,120Zm59.16,30.45L152,176h16a8,8,0,0,1,0,16H136a8,8,0,0,1-6.4-12.8l28.78-38.37A8,8,0,1,0,145.07,132a8,8,0,1,1-13.85-8A24,24,0,0,1,176,136,23.76,23.76,0,0,1,171.16,150.45Z"></path>
                      </svg>
                    </div>
                    <div className="flex flex-col gap-1">
                      <h2 className="text-[#111418] text-base font-bold leading-tight">Managing EZPass toll bill invoices with calendar reminders</h2>
                      <p className="text-[#637588] text-sm font-normal leading-normal">Never miss a payment with our automated calendar reminders for EZPass toll bill invoices</p>
                    </div>
                  </div>

                  <div className="flex flex-1 gap-3 rounded-lg border border-[#dce0e5] bg-white p-4 flex-col">
                    <div className="text-[#111418]" data-icon="ChartBar" data-size="24px" data-weight="regular">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 256 256">
                        <path d="M224,200h-8V40a8,8,0,0,0-8-8H152a8,8,0,0,0-8,8V80H96a8,8,0,0,0-8,8v40H48a8,8,0,0,0-8,8v64H32a8,8,0,0,0,0,16H224a8,8,0,0,0,0-16V48A16,16,0,0,0,224,32ZM160,48h40V200H160ZM104,96h40V200H104ZM56,144H88v56H56Z"></path>
                      </svg>
                    </div>
                    <div className="flex flex-col gap-1">
                      <h2 className="text-[#111418] text-base font-bold leading-tight">Querying investment document details</h2>
                      <p className="text-[#637588] text-sm font-normal leading-normal">Easily retrieve and analyze investment document details with our AI-powered query system</p>
                    </div>
                  </div>

                  <div className="flex flex-1 gap-3 rounded-lg border border-[#dce0e5] bg-white p-4 flex-col">
                    <div className="text-[#111418]" data-icon="Question" data-size="24px" data-weight="regular">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 256 256">
                        <path d="M140,180a12,12,0,1,1-12-12A12,12,0,0,1,140,180ZM128,72c-22.06,0-40,16.15-40,36v4a8,8,0,0,0,16,0v-4c0-11,10.77-20,24-20s24,9,24,20a8,8,0,0,0,8,8v8a8,8,0,0,0,16,0v-.72c18.24-3.35,32-17.9,32-35.28C168,88.15,150.06,72,128,72Zm104,56A104,104,0,1,1,128,24,104.11,104.11,0,0,1,232,128Zm-16,0a88,88,0,1,0-88,88A88.1,88.1,0,0,0,216,128Z"></path>
                      </svg>
                    </div>
                    <div className="flex flex-col gap-1">
                      <h2 className="text-[#111418] text-base font-bold leading-tight">Asking questions about tax reports</h2>
                      <p className="text-[#637588] text-sm font-normal leading-normal">Get quick and accurate answers to your tax report questions using our AI assistant</p>
                    </div>
                  </div>
                </div>
              </div>

              {/* Ready to get started section */}
              <div className="@container">
                <div className="flex flex-col justify-end gap-6 px-4 py-10 @[480px]:gap-8 @[480px]:px-10 @[480px]:py-20">
                  <div className="flex flex-col gap-2 text-center">
                    <h1 className="text-[#111418] tracking-light text-[32px] font-bold leading-tight @[480px]:text-4xl @[480px]:font-black @[480px]:leading-tight @[480px]:tracking-[-0.033em] max-w-[720px]">
                      Ready to get started?
                    </h1>
                    <p className="text-[#111418] text-base font-normal leading-normal max-w-[720px]">
                      Create a free account and start using Goffer AI today.
                    </p>
                  </div>
                  <div className="flex flex-1 justify-center">
                    <Link to="/register" className="flex min-w-[84px] max-w-[480px] cursor-pointer items-center justify-center overflow-hidden rounded-xl h-10 px-4 @[480px]:h-12 @[480px]:px-5 bg-[#1980e6] text-white text-sm font-bold leading-normal tracking-[0.015em] @[480px]:text-base @[480px]:font-bold @[480px]:leading-normal @[480px]:tracking-[0.015em] grow no-underline">
                      <span className="truncate">Get started with Goffer AI</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
