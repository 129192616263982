import React from 'react';
import MobileLayout from '../layout/MobileLayout';
import '../../../styles/mobile/files.scss';

const MobileFiles = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = React.useState(false);

  return (
    <MobileLayout 
      onMenuToggle={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
      title="Files"
    >
      <div className="mobile-files">
        <div className="files-header">
          <div className="search-bar">
            <input 
              type="text" 
              placeholder="Search files..."
              className="search-input"
            />
          </div>
        </div>

        <div className="files-list">
          {/* Files will be rendered here */}
        </div>

        <button className="floating-action-button">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 16 16">
            <path d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2z"/>
          </svg>
        </button>
      </div>
    </MobileLayout>
  );
};

export default MobileFiles; 