import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as endpoints from '../services/endpoints'; 
import FacebookLogin from '@greatsumini/react-facebook-login';
import { useGoogleLogin } from '@react-oauth/google';
import { trackEvent } from '../utils/analytics';

function Login() {
  const navigate = useNavigate();
  const [error, setError] = useState(''); // Add this state for error handling
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError(''); // Clear any previous errors
    const formData = new FormData(event.target);
    const credentials = {
      email: formData.get('email'),
      password: formData.get('password')
    };

    try {
      const response = await endpoints.login(credentials);
      if (response.data?.access_token) {
        trackEvent('login_success', { method: 'email' });
        localStorage.setItem('jwt_token', response.data.access_token);
        navigate('/dashboard');
      } else {
        setError('Invalid response from server');
      }
    } catch (error) {
      trackEvent('login_error', { 
        error_type: error.response?.data?.error || 'unknown_error'
      });
      console.error('Login error:', error.response?.data?.error || error.message);
      setError(error.response?.data?.error || 'An error occurred during login');
    } finally {
      setIsLoading(false);
    }
  };

  const googleLogin = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      // Note: Chrome's third-party cookie warning is expected
      // This is related to Google's Privacy Sandbox initiative
      // and doesn't affect functionality
      try {
        // First, get user info from Google
        const userInfoResponse = await fetch(
          'https://www.googleapis.com/oauth2/v3/userinfo',
          {
            headers: { Authorization: `Bearer ${codeResponse.access_token}` },
          }
        );
        const userInfo = await userInfoResponse.json();
        
        // Now send the ID token to your backend using your API service
        const response = await endpoints.googleLogin({
          token: codeResponse.access_token,
          userInfo: userInfo
        });

        if (response.data?.access_token) {
          trackEvent('login_success', { method: 'google' });
          localStorage.setItem('jwt_token', response.data.access_token);
          navigate('/dashboard');
        } else {
          setError('Invalid response from server');
        }
      } catch (error) {
        trackEvent('login_error', { 
          method: 'google',
          error_type: error.response?.data?.error || 'unknown_error'
        });
        console.error('Google login error:', error);
        setError(error.response?.data?.message || 'Google login failed');
      }
    },
    onError: (error) => {
      console.error('Login Failed:', error);
      setError('Google login failed. Please try again.');
    },
    flow: 'implicit',
  });

  const handleFacebookLogin = async (response) => {
    try {
      // Send the access token to your backend
      const fbResponse = await endpoints.facebookLogin(response.accessToken);
      
      if (fbResponse.data?.access_token) {
        localStorage.setItem('jwt_token', fbResponse.data.access_token);
        navigate('/dashboard');
      } else {
        setError('Invalid response from server');
      }
    } catch (error) {
      console.error('Facebook login error:', error);
      setError(error.response?.data?.message || 'Facebook login failed');
    }
  };

  return (
    <div className="flex min-h-screen items-center justify-center">
      <div className="w-[400px] bg-white rounded-lg p-8 shadow-sm">
        <h2 className="text-[#1980e6] text-base font-medium text-center">Log in to Goffer AI</h2>
        <p className="text-[#111418] text-sm text-center mt-1 mb-6">
          Access the power of AI to manage your documents effortlessly.
        </p>

        {error && (
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4">
            {error}
          </div>
        )}
        
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="text-sm text-[#111418]">Email</label>
            <input 
              name="email"
              type="email" 
              className="w-full mt-1 p-2 border border-[#dce0e5] rounded-lg" 
              required
              data-testid="email-input"
            />
          </div>
          
          <div>
            <label className="text-sm text-[#111418]">Password</label>
            <input 
              name="password"
              type="password" 
              className="w-full mt-1 p-2 border border-[#dce0e5] rounded-lg" 
              required
              data-testid="password-input"
            />
          </div>

          <div className="flex justify-end">
            <a 
              href="/forgot-password" 
              className="text-sm text-[#1980e6] hover:underline"
            >
              Forgot password?
            </a>
          </div>

          <button 
            type="submit" 
            className="w-full bg-[#1980e6] text-white rounded-lg py-2"
            data-testid="login-submit-button"
            disabled={isLoading}
          >
            {isLoading ? 'Logging in...' : 'Log in'}
          </button>

          <div className="relative mt-4 mb-4">
            <div className="absolute inset-0 flex items-center">
              <div className="w-full border-t border-gray-300"></div>
            </div>
            <div className="relative flex justify-center text-sm">
              <span className="px-2 bg-white text-gray-500">or</span>
            </div>
          </div>

          <button
            onClick={() => googleLogin()}
            className="w-full h-10 bg-white border border-gray-300 text-gray-700 rounded-[4px] flex items-center justify-center gap-3 hover:bg-gray-50 active:bg-gray-100 transition-colors duration-200 relative overflow-hidden"
            style={{
              boxShadow: 'rgba(0, 0, 0, 0.15) 0px 1px 1px 0px',
            }}
          >
            <svg width="20" height="20" viewBox="0 0 48 48" className="absolute left-3">
              <path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"/>
              <path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"/>
              <path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"/>
              <path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"/>
            </svg>
            <span className="text-sm font-medium">Continue with Google</span>
          </button>

          <div className="w-full">
            <FacebookLogin
              appId="3888383911488656"
              onSuccess={handleFacebookLogin}
              onFail={(error) => {
                console.error('Facebook login failed:', error);
                setError('Facebook login failed. Please try again.');
              }}
              style={{
                backgroundColor: '#1877f2',
                color: '#fff',
                padding: '8px 16px',
                border: 'none',
                borderRadius: '8px',
                width: '100%',
                fontSize: '14px',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '8px'
              }}
            />
          </div>

          <p className="text-center text-sm">
            Don't have an account? <a href="/register" className="text-[#1980e6]">Register here</a>
          </p>
        </form>
      </div>
    </div>
  );
}

export default Login;
