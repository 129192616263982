import React, { useState, useEffect } from 'react';
import * as endpoints from '../services/endpoints';

function UploadModal({ isOpen, onClose }) {
  const [link, setLink] = useState('');
  const [uploading, setUploading] = useState(false);
  const [uploadStatus, setUploadStatus] = useState(null);

  // Reset states when modal opens/closes
  useEffect(() => {
    if (isOpen) {
      setLink('');
      setUploadStatus(null);
      setUploading(false);
    }
  }, [isOpen]);

  const handleLinkChange = (e) => {
    setLink(e.target.value);
  };

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const handleFileUpload = async (event) => {
    const files = event.target.files;
    if (!files || files.length === 0) return;

    setUploading(true);
    setUploadStatus(null);

    const formData = new FormData();
    Array.from(files).forEach(file => {
      formData.append('file', file);
    });

    try {
      await endpoints.uploadFile(formData);
      setUploadStatus({
        success: true,
        message: `Successfully uploaded ${files.length} file(s)`
      });
    } catch (error) {
      setUploadStatus({
        success: false,
        message: error.response?.data?.message || 'Upload failed'
      });
    } finally {
      setUploading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div 
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
      onClick={handleOverlayClick}
    >
      <div className="bg-white rounded-xl w-full max-w-xl mx-4">
        <div className="p-6">
          <h2 className="text-xl font-semibold text-gray-900 mb-6">Upload Files</h2>
          <p className="text-gray-600 mb-6">Insert a link or upload a document</p>

          <div className="space-y-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">Link</label>
              <input
                type="text"
                placeholder="Http://"
                value={link}
                onChange={handleLinkChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">Upload files</label>
              <div 
                className="border-2 border-dashed border-gray-300 rounded-lg p-8 text-center cursor-pointer hover:border-blue-500 transition-colors"
                onClick={() => document.getElementById('file-upload').click()}
              >
                <div className="flex flex-col items-center">
                  <svg className="w-8 h-8 text-gray-400 mb-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                  </svg>
                  <p className="text-gray-600">Upload one or more files</p>
                </div>
                <input
                  id="file-upload"
                  type="file"
                  multiple
                  className="hidden"
                  onChange={handleFileUpload}
                  accept=".txt,.pdf,.doc,.docx,.xlsx,.csv,.png"
                />
              </div>
            </div>
          </div>

          {uploadStatus && (
            <div className={`mt-4 p-3 rounded-lg ${uploadStatus.success ? 'bg-green-50 text-green-700' : 'bg-red-50 text-red-700'}`}>
              {uploadStatus.message}
            </div>
          )}

          <div className="mt-6 flex justify-end gap-3">
            <button
              onClick={onClose}
              className="px-4 py-2 bg-gray-500 text-white rounded-lg text-sm font-medium hover:bg-gray-600 transition-colors"
            >
              Cancel
            </button>
            <button
              disabled={uploading}
              className="px-4 py-2 bg-[#3498db] text-white rounded-lg text-sm font-medium hover:bg-[#2980b9] transition-colors disabled:opacity-50"
            >
              {uploading ? 'Uploading...' : 'Upload'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UploadModal; 