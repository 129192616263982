import React, { useState, useEffect } from 'react';
import { getChats, deleteChat } from '../services/endpoints';
import '../styles/ChatSidebar.css';

const ChatSidebar = ({ isOpen, onClose, onChatSelect, onFileSearchClose }) => {
  const [chats, setChats] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    console.log('ChatSidebar isOpen:', isOpen);
    if (isOpen) {
      fetchChats();
    }
  }, [isOpen]);

  const fetchChats = async () => {
    try {
      setLoading(true);
      const response = await getChats();
      const chatData = response.data?.chats || response.chats || [];
      setChats(chatData);
      setError(null);
    } catch (err) {
      setError('Failed to fetch chats');
      console.error('Error fetching chats:', err);
      setChats([]);
    } finally {
      setLoading(false);
    }
  };

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const today = new Date();
    
    // Check if the timestamp is from today
    if (date.toDateString() === today.toDateString()) {
      return date.toLocaleTimeString([], { 
        hour: '2-digit', 
        minute: '2-digit'
      });
    }
    
    // For older messages, show the date
    return date.toLocaleDateString([], {
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  const handleDeleteChat = async (chatId) => {
    if (window.confirm('Are you sure you want to delete this chat?')) {
      try {
        await deleteChat(chatId);
        setChats(chats.filter(chat => chat.id !== chatId));
      } catch (err) {
        setError('Failed to delete chat');
        console.error('Error:', err);
      }
    }
  };

  const handleChatClick = (chat) => {
    if (onFileSearchClose) {
      onFileSearchClose();
    }
    onChatSelect(chat);
    onClose();
  };

  return (
    <div className={`chat-sidebar ${isOpen ? 'open' : ''}`}>
      <div className="chat-sidebar-header">
        <h2>Chat History</h2>
        <button onClick={onClose} className="close-button">
          <i className="fas fa-times"></i>
        </button>
      </div>

      {loading && <div className="loading-state">Loading chats...</div>}
      {error && <div className="error-state">Error: {error}</div>}
      
      {!loading && !error && (
        <div className="chat-list">
          {(!chats || chats.length === 0) ? (
            <div className="empty-state">
              <p>No conversations yet.</p>
            </div>
          ) : (
            <ul className="chat-items">
              {chats.map(chat => (
                <li key={chat.id} className="chat-item">
                  <div 
                    className="chat-item-content"
                    onClick={() => handleChatClick(chat)}
                  >
                    <div className="chat-title">
                      {chat.title}
                    </div>
                    <div className="chat-item-footer">
                      <span className="chat-date">
                        {formatTimestamp(chat.updated_at)}
                      </span>
                      <button 
                        className="delete-chat-button"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDeleteChat(chat.id);
                        }}
                      >
                        <i className="fas fa-trash"></i>
                      </button>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          )}
        </div>
      )}
    </div>
  );
};

export default ChatSidebar; 