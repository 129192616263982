import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getChats } from '../../../services/endpoints';
import '../../../styles/mobile/menu.scss';

const MobileMenu = ({ isOpen, onClose }) => {
  const navigate = useNavigate();
  const [chats, setChats] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (isOpen) {
      loadChats();
    }
  }, [isOpen]);

  const loadChats = async () => {
    try {
      setIsLoading(true);
      const response = await getChats();
      setChats(response.data.chats || []);
    } catch (error) {
      console.error('Error loading chats:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChatClick = (chatId) => {
    navigate(`/m/chat/${chatId}`);
    onClose();
  };

  const handleMenuItemClick = (route) => {
    navigate(route);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <>
      <div className="mobile-menu-overlay" onClick={onClose} />
      <div className="mobile-menu">
        {/* Chat History Section */}
        <div className="menu-section chat-history">
          <h2>Chat History</h2>
          <div className="chat-list">
            {isLoading ? (
              <div className="loading-state">Loading chats...</div>
            ) : chats.length > 0 ? (
              chats.map((chat) => (
                <button
                  key={chat.id}
                  className="chat-item"
                  onClick={() => handleChatClick(chat.id)}
                >
                  <div className="chat-preview">
                    <span className="chat-title">
                      {chat.title || chat.messages[0]?.content?.slice(0, 50) || 'New Chat'}
                    </span>
                    <span className="chat-date">
                      {new Date(chat.created_at).toLocaleDateString()}
                    </span>
                  </div>
                </button>
              ))
            ) : (
              <div className="empty-state">No chat history</div>
            )}
          </div>
        </div>

        {/* Bottom Menu Items */}
        <div className="menu-section bottom-menu">
          <button 
            className="menu-item"
            onClick={() => handleMenuItemClick('/m/search')}
          >
            <div className="icon-container">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <path d="M15.5 14h-.79l-.28-.27a6.5 6.5 0 0 0 1.48-5.34c-.47-2.78-2.79-5-5.59-5.34a6.505 6.505 0 0 0-7.27 7.27c.34 2.8 2.56 5.12 5.34 5.59a6.5 6.5 0 0 0 5.34-1.48l.27.28v.79l4.25 4.25c.41.41 1.08.41 1.49 0 .41-.41.41-1.08 0-1.49L15.5 14zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"/>
              </svg>
            </div>
            <span>Search</span>
          </button>
          <button 
            className="menu-item"
            onClick={() => handleMenuItemClick('/m/reminders')}
          >
            <div className="icon-container">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <path d="M12 22c1.1 0 2-.9 2-2h-4c0 1.1.9 2 2 2zm6-6v-5c0-3.07-1.63-5.64-4.5-6.32V4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68C7.64 5.36 6 7.92 6 11v5l-2 2v1h16v-1l-2-2zm-2 1H8v-6c0-2.48 1.51-4.5 4-4.5s4 2.02 4 4.5v6z"/>
              </svg>
            </div>
            <span>Reminders</span>
          </button>
          <button 
            className="menu-item"
            onClick={() => handleMenuItemClick('/m/profile')}
          >
            <div className="icon-container">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"/>
              </svg>
            </div>
            <span>Profile</span>
          </button>
        </div>
      </div>
    </>
  );
};

export default MobileMenu; 