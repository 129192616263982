import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Helmet } from 'react-helmet-async';

const BlogPost = () => {
  const [post] = React.useState({
    title: "Enhanced Document Processing & Alert Generation",
    date: "January 26, 2025",
    description: "Your personal assistant just got smarter! Now with enhanced abilities to read text from images, better understand PDFs, and manage your important dates more intelligently.",
    keywords: "document processing, OCR, PDF reading, smart alerts, date detection, mobile notifications, personal assistant, AI assistant",
    content: `
# January 2025 Updates: Enhanced Document Processing & Alert Generation

We're excited to announce significant improvements to our personal assistant system, making it even smarter at understanding your documents and managing your important dates!

## 🔍 Smarter Document Understanding

### Multi-Format Support
- **Image Processing**: Your assistant can now read text from any image
  - Works with photos of documents, receipts, and handwritten notes
  - Handles rotated or skewed images automatically
  - Supports multiple languages
  - Works even with complex document layouts

- **PDF Processing**: Better handling of all your PDF documents
  - Extracts information from contracts, invoices, and reports
  - Works with multi-page documents
  - Handles scanned PDFs and digital documents

### Intelligent Text Understanding
- Smarter processing of long documents
  - Maintains context and relationships between information
  - Better understanding of document structure
  - More accurate information extraction

## ⏰ Better Alert Management

### Smart Date Detection
- Automatically finds important dates from:
  - Email messages
  - PDF attachments
  - Images of documents
  - Preserves the context of each date

### Intelligent Alert Organization
- Smarter prioritization of your alerts
- Filter alerts by date ranges
- No more duplicate reminders
- Each alert includes relevant context from the source

## 🎨 Improved User Experience

### Faster & Smoother Interface
- More responsive interface
- Quicker loading times
- Better performance on all devices

### Enhanced Mobile Experience
- Better display on mobile devices
- Improved notification handling
- Easier to read and manage alerts on the go

## 🚀 What's Next?
We're continuing to improve your experience with:
- Even smarter document understanding
- More sophisticated alert prioritization
- Support for more document types
- Enhanced mobile features

Stay tuned for more updates!
    `
  });

  return (
    <>
      <Helmet>
        <title>{post.title} | Goffer.AI Blog</title>
        <meta name="description" content={post.description} />
        <meta name="keywords" content={post.keywords} />
        
        {/* Open Graph / Facebook */}
        <meta property="og:type" content="article" />
        <meta property="og:title" content={post.title} />
        <meta property="og:description" content={post.description} />
        <meta property="article:published_time" content="2025-01-26" />
        
        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={post.title} />
        <meta name="twitter:description" content={post.description} />
        
        {/* Additional SEO */}
        <meta name="author" content="Goffer.AI Team" />
        <link rel="canonical" href="https://goffer.ai/blog/january-2025-updates" />
      </Helmet>
      <div className="max-w-4xl mx-auto px-4 py-8">
        <article className="prose lg:prose-xl">
          <div className="mb-8">
            <h1 className="text-3xl font-bold">{post.title}</h1>
            <div className="text-[#637588]">{post.date}</div>
          </div>
          <ReactMarkdown>{post.content}</ReactMarkdown>
        </article>
      </div>
    </>
  );
};

export default BlogPost; 