export const REMINDER_TYPE_DISPLAY = {
  PAYMENT_DUE: 'Payment Due',
  EXPIRATION: 'Document Expiration',
  RENEWAL: 'Contract Renewal',
  APPOINTMENT: 'Appointment',
  CUSTOM: 'Custom'
};

export const REMINDER_STATUS_DISPLAY = {
  PENDING: 'Pending',
  ACKNOWLEDGED: 'Acknowledged',
  COMPLETED: 'Completed',
  DISMISSED: 'Dismissed'
};

export const REMINDER_PRIORITY_DISPLAY = {
  HIGH: 'High',
  MEDIUM: 'Medium',
  LOW: 'Low'
};

// Helper function to get display value with fallback
export const getDisplayValue = (mapping, value) => {
  return mapping[value] || value;
}; 