import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getUserProfile, completeOnboarding } from '../../../services/endpoints';

const MobileOnboarding = () => {
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const [userEmail, setUserEmail] = useState('');

  useEffect(() => {
    fetchUserProfile();
  }, []);

  const fetchUserProfile = async () => {
    try {
      const response = await getUserProfile();
      setUserEmail(response.data.goffer_email);
    } catch (error) {
      console.error('Error fetching user profile:', error);
      setError('Unable to fetch user information');
    }
  };

  const handleContinue = async () => {
    setError('');
    try {
      const response = await completeOnboarding();
      if (response.status === 200) {
        navigate('/m');
      }
    } catch (error) {
      console.error('Onboarding error:', error.response?.data?.error || error.message);
      setError(error.response?.data?.error || 'An error occurred during onboarding');
    }
  };

  return (
    <div className="flex flex-col min-h-screen bg-white">
      <div className="flex-1 p-4">
        <h2 className="text-2xl font-bold mb-6 text-left">Welcome to Goffer AI</h2>
        
        {error && <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4 text-left">{error}</div>}
        
        <div className="space-y-6">
          <div className="info-section">
            <h3 className="text-xl font-semibold mb-3 text-left">Goffer, your personal AI assistant is ready!</h3>
            <p className="text-gray-600 mb-4 text-left">
              Congratulations! You've successfully set up your Goffer AI assistant. 
              Moving forward, your assistant will automatically index and analyze any documents 
              sent to your dedicated Goffer email:
            </p>
            
            <div className="bg-gray-50 p-4 rounded-lg flex items-center space-x-2 mb-6">
              <i className="fas fa-envelope text-gray-600"></i>
              <div className="text-left">
                <strong className="block text-sm">Your Goffer Email:</strong>
                <code className="text-sm text-blue-600">{userEmail || 'Loading...'}</code>
              </div>
            </div>
          </div>

          <div className="features-section">
            <h3 className="text-xl font-semibold mb-4 text-left">How to Use Goffer AI</h3>
            
            <div className="space-y-4">
              <div className="feature-item flex items-start space-x-3">
                <i className="fas fa-paper-plane mt-1 text-blue-500"></i>
                <div className="text-left">
                  <h4 className="font-medium">Forward or Send Emails</h4>
                  <p className="text-gray-600">Simply forward any emails with attachments to your Goffer email address. Your AI assistant will process all documents automatically.</p>
                </div>
              </div>

              <div className="feature-item flex items-start space-x-3">
                <i className="fas fa-upload mt-1 text-blue-500"></i>
                <div className="text-left">
                  <h4 className="font-medium">Manual Upload</h4>
                  <p className="text-gray-600">Use the upload button in the dashboard to manually add documents to your workspace.</p>
                </div>
              </div>

              <div className="feature-item flex items-start space-x-3">
                <i className="fas fa-comments mt-1 text-blue-500"></i>
                <div className="text-left">
                  <h4 className="font-medium">Chat with Your Documents</h4>
                  <p className="text-gray-600">Once processed, you can ask questions and get insights about your documents using our AI-powered chat interface.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="p-4 border-t">
        <button
          onClick={handleContinue}
          className="w-full px-4 py-2 bg-[#3498db] text-white rounded-lg text-sm font-medium hover:bg-[#2980b9] transition-colors"
        >
          Continue to Dashboard
        </button>
      </div>
    </div>
  );
};

export default MobileOnboarding; 