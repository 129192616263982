import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getUserProfile, getUnreadNotificationsCount } from '../services/endpoints';
import UploadModal from './UploadModal';
import NotificationsDropdown from './NotificationsDropdown';
import { trackEvent } from '../utils/analytics';

function AuthenticatedNavbar({ 
  onNotificationsClick, 
}) {
  const navigate = useNavigate();
  const [profile, setProfile] = useState({
    first_name: 'Guest',
    last_name: 'User',
    email: 'loading@example.com',
    goffer_email: 'loading@goffer.com'
  });
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0);

  const fetchUnreadCount = async () => {
    try {
      const response = await getUnreadNotificationsCount();
      setUnreadCount(response.data.count);
    } catch (error) {
      console.error('Error fetching unread count:', error);
    }
  };

  useEffect(() => {
    // Initial fetch
    fetchUnreadCount();
    
    // Set up polling interval
    const interval = setInterval(fetchUnreadCount, 60000); // Poll every minute
    
    // Add global function to update count
    window.updateNotificationCount = fetchUnreadCount;
    
    // Cleanup interval on component unmount
    return () => {
      if (interval) {
        clearInterval(interval);
      }
      // Remove global function
      delete window.updateNotificationCount;
    };
  }, []); // Empty dependency array to run only once on mount

  useEffect(() => {
    const fetchProfile = async () => {
      const token = localStorage.getItem('jwt_token');
      if (!token) {
        console.log('No token found, redirecting to login');
        navigate('/login', { replace: true });
        return;
      }

      try {
        console.log('Fetching profile...');
        const response = await getUserProfile();
        console.log('Profile response:', response);
        setProfile(response.data);
      } catch (error) {
        console.error('Error fetching profile:', error);
        if (error.response?.status === 401) {
          localStorage.removeItem('jwt_token');
          navigate('/login', { replace: true });
        }
      }
    };

    fetchProfile();
  }, [navigate]);

  const handleLogout = () => {
    localStorage.removeItem('jwt_token');
    navigate('/login', { replace: true });
  };

  const handleUploadClick = () => {
    setIsUploadModalOpen(true);
  };

  const handleNotificationsClick = () => {
    // Track notification icon click
    trackEvent('notification_icon_click', {
      unread_count: unreadCount,
      location: 'top_nav'
    });

    setIsNotificationsOpen(!isNotificationsOpen);
    // Fetch latest count when opening the dropdown
    if (!isNotificationsOpen) {
      fetchUnreadCount();
    }
    if (onNotificationsClick) {
      onNotificationsClick();
    }
  };

  return (
    <>
      <nav className="flex items-center justify-between whitespace-nowrap border-b border-solid border-b-[#f0f2f4] px-4 md:px-10 py-3">
        <div className="flex items-center gap-2 md:gap-4">
          <div className="text-[#111418] text-base md:text-lg font-bold">Goffer AI</div>
        </div>
        
        <div className="flex items-center gap-4">
          
          <div className="hidden md:flex items-center text-gray-600">
            Send documents to <span className="text-[#3498db] mx-2">{profile.goffer_email}</span> or
          </div>
          
          <button 
            onClick={handleUploadClick}
            className="flex items-center gap-2 px-4 py-2 bg-[#3498db] text-white rounded-lg text-sm font-medium hover:bg-[#2980b9] transition-colors"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
              <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
              <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708l3-3z"/>
            </svg>
            Upload a file
          </button>

          <div className="flex items-center gap-3">
            <button 
              className="relative p-2 hover:bg-gray-100 rounded-full"
              onClick={handleNotificationsClick}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 16 16">
                <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zm.995-14.901a1 1 0 1 0-1.99 0A5.002 5.002 0 0 0 3 6c0 1.098-.5 6-2 7h14c-1.5-1-2-5.902-2-7 0-2.42-1.72-4.44-4.005-4.901z"/>
              </svg>
              {unreadCount > 0 && (
                <span className="absolute top-0 right-0 bg-red-500 text-white text-xs w-4 h-4 flex items-center justify-center rounded-full">
                  {unreadCount}
                </span>
              )}
            </button>
            
            <button 
              onClick={handleLogout}
              className="px-4 py-2 bg-[#3498db] text-white rounded-lg text-sm font-medium hover:bg-[#2980b9] transition-colors"
            >
              Log out
            </button>
          </div>
          
          <div className="relative">
            <NotificationsDropdown 
              isOpen={isNotificationsOpen}
              onClose={() => setIsNotificationsOpen(false)}
              onNotificationUpdate={fetchUnreadCount}
            />
          </div>
        </div>
      </nav>

      <UploadModal 
        isOpen={isUploadModalOpen} 
        onClose={() => setIsUploadModalOpen(false)} 
      />
    </>
  );
}

export default AuthenticatedNavbar;

