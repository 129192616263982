import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const Blog = () => {
  return (
    <>
      <Helmet>
        <title>Goffer.AI Blog - Smart Document Processing & Alert Management</title>
        <meta name="description" content="Stay updated with the latest features and improvements in document processing, smart alerts, and AI-powered personal assistance from Goffer.AI." />
        <meta name="keywords" content="document management, smart alerts, AI assistant, personal assistant, document processing, OCR, PDF processing, mobile notifications" />
        
        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Goffer.AI Blog - Smart Document Processing & Alert Management" />
        <meta property="og:description" content="Stay updated with the latest features and improvements in document processing, smart alerts, and AI-powered personal assistance from Goffer.AI." />
        
        {/* Twitter */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content="Goffer.AI Blog - Smart Document Processing & Alert Management" />
        <meta name="twitter:description" content="Stay updated with the latest features and improvements in document processing, smart alerts, and AI-powered personal assistance from Goffer.AI." />
        
        {/* Additional SEO */}
        <meta name="author" content="Goffer.AI Team" />
        <link rel="canonical" href="https://goffer.ai/blog" />
      </Helmet>
      <div className="max-w-4xl mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold mb-8 text-left">Goffer.AI Blog</h1>
        
        <div className="space-y-8">
          {/* Latest Blog Post */}
          <article className="border border-[#f0f2f4] rounded-xl p-6 hover:shadow-lg transition-shadow">
            <h2 className="text-2xl font-bold mb-2 text-left">
              <Link to="/blog/january-2025-updates" className="text-[#111418] hover:text-[#3498db]">
                Enhanced Document Processing & Alert Generation
              </Link>
            </h2>
            <div className="text-[#637588] mb-4 text-left">
              January 26, 2025 • 4 min read
            </div>
            <p className="text-[#111418] mb-4 text-left">
              Your personal assistant just got smarter! Now with enhanced abilities to read text from images, better understand PDFs, and manage your important dates more intelligently.
            </p>
            <div className="flex flex-wrap gap-2 text-left">
              <span className="px-3 py-1 bg-[#f0f2f4] text-[#637588] rounded-full text-sm">smart-assistant</span>
              <span className="px-3 py-1 bg-[#f0f2f4] text-[#637588] rounded-full text-sm">document-reading</span>
              <span className="px-3 py-1 bg-[#f0f2f4] text-[#637588] rounded-full text-sm">alerts</span>
            </div>
          </article>

          {/* Previous Blog Post */}
          <article className="border border-[#f0f2f4] rounded-xl p-6 hover:shadow-lg transition-shadow">
            <h2 className="text-2xl font-bold mb-2 text-left">
              <Link to="/blog/enhanced-notification-system" className="text-[#111418] hover:text-[#3498db]">
                Enhanced Notification System: Smarter Document Management
              </Link>
            </h2>
            <div className="text-[#637588] mb-4 text-left">
              January 21, 2025 • 5 min read
            </div>
            <p className="text-[#111418] mb-4 text-left">
              We're excited to announce significant improvements to our notification system, making document management smarter and more efficient than ever!
            </p>
            <div className="flex flex-wrap gap-2 text-left">
              <span className="px-3 py-1 bg-[#f0f2f4] text-[#637588] rounded-full text-sm">notifications</span>
              <span className="px-3 py-1 bg-[#f0f2f4] text-[#637588] rounded-full text-sm">updates</span>
              <span className="px-3 py-1 bg-[#f0f2f4] text-[#637588] rounded-full text-sm">features</span>
            </div>
          </article>
        </div>
      </div>
    </>
  );
};

export default Blog; 