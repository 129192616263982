import React from 'react';

function Terms() {
  return (
    <div className="relative flex size-full min-h-screen flex-col bg-white group/design-root overflow-x-hidden" 
         style={{ fontFamily: 'Inter, "Noto Sans", sans-serif' }}>
      <div className="layout-container flex h-full grow flex-col">
        <div className="px-40 flex flex-1 justify-center py-5">
          <div className="layout-content-container flex flex-col max-w-[960px] flex-1">
            <div className="flex flex-col gap-10 px-4 py-10">
              {/* Header */}
              <div className="flex flex-col gap-4">
                <h1 className="text-[#111418] text-[32px] font-bold leading-tight tracking-[-0.033em]">
                  Terms of Service & Notification Consent
                </h1>
                <p className="text-[#637588] text-base leading-normal">
                  Last updated: {new Date().toLocaleDateString()}
                </p>
              </div>

              {/* Notification Consent Section */}
              <div className="flex flex-col gap-6">
                <h2 className="text-[#111418] text-xl font-bold leading-tight">Notification Consent & Communications</h2>
                
                {/* Key Points Box */}
                <div className="bg-[#f0f2f4] rounded-lg p-6">
                  <h3 className="text-[#111418] font-bold mb-4">SMS Notification Terms:</h3>
                  <ul className="list-disc list-inside space-y-2 text-[#637588]">
                    <li>Message and data rates may apply</li>
                    <li>You can opt-out at any time by texting STOP</li>
                    <li>Consent is not required as a condition of purchase</li>
                    <li>Frequency of messages will vary based on account activity</li>
                    <li>For help, text HELP or contact support@goffer.ai</li>
                  </ul>
                </div>

                {/* Types of Notifications */}
                <div className="flex flex-col gap-4">
                  <h3 className="text-[#111418] font-bold">Types of Notifications</h3>
                  <p className="text-[#637588]">By accepting these terms, you agree to receive notifications including:</p>
                  <ul className="list-disc list-inside space-y-2 text-[#637588]">
                    <li>Document expiration alerts</li>
                    <li>Payment due date reminders</li>
                    <li>Contract renewal notifications</li>
                    <li>Account security alerts</li>
                    <li>Processing status updates</li>
                    <li>Custom deadline reminders</li>
                  </ul>
                </div>

                {/* Notification Schedule */}
                <div className="flex flex-col gap-4">
                  <h3 className="text-[#111418] font-bold">Default Notification Schedule</h3>
                  <ul className="list-disc list-inside space-y-2 text-[#637588]">
                    <li>Payment Reminders: 30 days, 7 days, and 1 day before due date</li>
                    <li>Document Expiration: 60 days, 30 days, and 7 days before expiration</li>
                    <li>Contract Renewal: 90 days, 30 days, and 7 days before renewal</li>
                    <li>Appointments: 7 days, 1 day, and day of appointment</li>
                  </ul>
                </div>

                {/* User Control */}
                <div className="flex flex-col gap-4">
                  <h3 className="text-[#111418] font-bold">User Control & Opt-Out</h3>
                  <p className="text-[#637588]">You can manage your notification preferences through:</p>
                  <ul className="list-disc list-inside space-y-2 text-[#637588]">
                    <li>Account Settings {'>>'} Notification Preferences</li>
                    <li>Replying "STOP" to any SMS notification</li>
                    <li>Contacting customer support</li>
                  </ul>
                </div>

                {/* Data Privacy & Security */}
                <div className="flex flex-col gap-4">
                  <h3 className="text-[#111418] font-bold">Data Privacy & Security</h3>
                  <p className="text-[#637588]">We are committed to protecting your data:</p>
                  <ul className="list-disc list-inside space-y-2 text-[#637588]">
                    <li>All data is encrypted in transit and at rest</li>
                    <li>Your personal data is never sold to third parties</li>
                    <li>You can request data export or deletion at any time</li>
                    <li>We comply with GDPR and CCPA regulations</li>
                    <li>Access logs are maintained for security purposes</li>
                  </ul>
                </div>

                {/* AI Assistant Usage */}
                <div className="flex flex-col gap-4">
                  <h3 className="text-[#111418] font-bold">AI Assistant Usage</h3>
                  <div className="bg-[#f0f2f4] rounded-lg p-6">
                    <ul className="list-disc list-inside space-y-2 text-[#637588]">
                      <li>Our AI assistant processes your queries to provide personalized responses</li>
                      <li>AI interactions are logged for service improvement</li>
                      <li>You maintain ownership of your content and documents</li>
                      <li>AI-generated suggestions are recommendations only</li>
                      <li>Human review may be involved for quality assurance</li>
                    </ul>
                  </div>
                </div>

                {/* Service Limitations */}
                <div className="flex flex-col gap-4">
                  <h3 className="text-[#111418] font-bold">Service Limitations</h3>
                  <ul className="list-disc list-inside space-y-2 text-[#637588]">
                    <li>Service availability may vary based on maintenance or updates</li>
                    <li>Response times are not guaranteed</li>
                    <li>AI capabilities have inherent limitations</li>
                    <li>Some features may require premium subscription</li>
                    <li>Usage limits may apply to prevent abuse</li>
                  </ul>
                </div>

                {/* Contact Information */}
                <div className="flex flex-col gap-4">
                  <h3 className="text-[#111418] font-bold">Contact Us</h3>
                  <div className="bg-[#f0f2f4] rounded-lg p-6">
                    <p className="text-[#111418]">Email: support@goffer.ai</p>
                    <p className="text-[#111418]">Web: https://www.goffer.ai/contact</p>
                  </div>
                </div>
              </div>

              {/* SMS Consent Management Section */}
              <div className="flex flex-col gap-6">
                <h2 className="text-[#111418] text-xl font-bold leading-tight">Managing Your SMS Notifications</h2>
                
                {/* Opt-out Methods Box */}
                <div className="bg-[#f0f2f4] rounded-lg p-6">
                  <h3 className="text-[#111418] font-bold mb-4">How to Opt-Out of SMS Messages</h3>
                  
                  <div className="space-y-6">
                    {/* Text Message Method */}
                    <div className="flex items-start gap-4">
                      <div className="bg-white p-3 rounded-lg">
                        <svg className="w-6 h-6 text-[#1980e6]" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z" />
                        </svg>
                      </div>
                      <div>
                        <h4 className="text-[#111418] font-medium mb-1">Via Text Message</h4>
                        <p className="text-[#637588]">Reply "STOP" to any of our messages to unsubscribe from all SMS notifications.</p>
                      </div>
                    </div>

                    {/* Account Settings Method */}
                    <div className="flex items-start gap-4">
                      <div className="bg-white p-3 rounded-lg">
                        <svg className="w-6 h-6 text-[#1980e6]" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                        </svg>
                      </div>
                      <div>
                        <h4 className="text-[#111418] font-medium mb-1">Via Account Settings</h4>
                        <p className="text-[#637588]">
                          1. Log in to your account<br />
                          2. Go to Settings → Notifications<br />
                          3. Toggle off "SMS Notifications"
                        </p>
                      </div>
                    </div>

                    {/* Customer Support Method */}
                    <div className="flex items-start gap-4">
                      <div className="bg-white p-3 rounded-lg">
                        <svg className="w-6 h-6 text-[#1980e6]" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M18.364 5.636l-3.536 3.536m0 5.656l3.536 3.536M9.172 9.172L5.636 5.636m3.536 9.192l-3.536 3.536M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-5 0a4 4 0 11-8 0 4 4 0 018 0z" />
                        </svg>
                      </div>
                      <div>
                        <h4 className="text-[#111418] font-medium mb-1">Via Customer Support</h4>
                        <p className="text-[#637588]">
                          Contact our support team at{' '}
                          <a href="mailto:support@goffer.ai" className="text-[#1980e6] hover:underline">
                            support@goffer.ai
                          </a>
                          {' '}to request SMS opt-out.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="mt-6 p-4 bg-white rounded-lg border border-[#dce0e5]">
                    <p className="text-[#637588] text-sm">
                      <span className="font-medium text-[#111418]">Note:</span> After opting out, you may receive one final message confirming your unsubscription. 
                      Standard message and data rates may apply.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Terms; 