import React from 'react';
import { Helmet } from 'react-helmet-async';

function UseCases() {
  return (
    <>
      <Helmet>
        <title>Use Cases - Goffer.AI | Real-World Applications</title>
        <meta name="description" content="See how Goffer.AI transforms document management across industries. From invoice processing to contract management, discover practical applications of our AI-powered assistant." />
        <meta name="keywords" content="document management use cases, AI applications, business automation, invoice processing, contract management, document organization examples" />
        
        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Use Cases - Goffer.AI | Real-World Applications" />
        <meta property="og:description" content="Explore real-world applications of Goffer.AI's document management capabilities. See how businesses streamline their operations with our AI assistant." />
        <meta property="og:image" content="https://goffer.ai/use-cases-og.jpg" />
        
        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Use Cases - Goffer.AI | Real-World Applications" />
        <meta name="twitter:description" content="Explore real-world applications of Goffer.AI's document management capabilities. See how businesses streamline their operations with our AI assistant." />
        <meta name="twitter:image" content="https://goffer.ai/use-cases-twitter.jpg" />
        
        {/* Additional SEO */}
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Goffer.AI Team" />
        <link rel="canonical" href="https://goffer.ai/use-cases" />
      </Helmet>

      <div className="relative flex size-full min-h-screen flex-col bg-white group/design-root overflow-x-hidden" style={{ fontFamily: 'Inter, "Noto Sans", sans-serif' }}>
        <div className="layout-container flex h-full grow flex-col">
          
          {/* Main Content */}
          <div className="px-40 flex flex-1 justify-center py-5">
            <div className="layout-content-container flex flex-col max-w-[960px] flex-1">
              <div className="flex flex-col gap-10 px-4 py-10">
                <div className="flex flex-col gap-4">
                  <h1 className="text-[#111418] text-[32px] font-bold leading-tight tracking-[-0.033em] max-w-[720px]">
                    Use Cases
                  </h1>
                  <p className="text-[#111418] text-base font-normal leading-normal max-w-[720px]">
                    Discover how Goffer AI can streamline your workflows across various industries and tasks. Below are some key use cases and examples of how our service can be leveraged to its fullest potential.
                  </p>
                </div>

                {/* Use Case Example */}
                <div className="flex flex-col gap-6">
                  <h2 className="text-[#111418] text-xl font-bold leading-tight">Invoice Management</h2>
                  <p className="text-[#637588] text-base font-normal leading-normal">
                    Automate your invoice processing, task creation, and errand handling.
                  </p>
                  <div className="bg-[#f0f2f4] rounded-lg p-6">
                    <p className="text-[#111418] font-medium">Example Document:</p>
                    <p className="text-[#637588]">Vendor invoice with details such as invoice number, amount due, and due date.</p>
                    <p className="text-[#111418] font-medium mt-4">Ask the Bot:</p>
                    <p className="text-[#637588]">“What's the total amount due and when is it due?”</p>
                    <p className="text-[#111418] font-medium mt-4">Bot Response:</p>
                    <p className="text-[#637588]">“The total amount due is $1,500, and the due date is September 15, 2024. Would you like me to set a reminder for this payment?”</p>
                  </div>
                </div>

                {/* Additional Use Cases */}
                <div className="flex flex-col gap-6">
                  <h2 className="text-[#111418] text-xl font-bold leading-tight">Contract Review</h2>
                  <p className="text-[#637588] text-base font-normal leading-normal">
                    Analyze contracts, extract key terms, and manage renewals effortlessly.
                  </p>
                  <div className="bg-[#f0f2f4] rounded-lg p-6">
                    <p className="text-[#111418] font-medium">Example Document:</p>
                    <p className="text-[#637588]">Supplier agreement contract.</p>
                    <p className="text-[#111418] font-medium mt-4">Ask the Bot:</p>
                    <p className="text-[#637588]">"What are the key terms of this contract, and when is it set to expire?"</p>
                    <p className="text-[#111418] font-medium mt-4">Bot Response:</p>
                    <p className="text-[#637588]">"The key terms include a delivery schedule of 30 days, a payment of $10,000 upon delivery, and the contract expires on December 31, 2024. Would you like to create a task for contract renewal?"</p>
                  </div>
                </div>

                {/* Repeat similar blocks for each use case */}
                <div className="flex flex-col gap-6">
                  <h2 className="text-[#111418] text-xl font-bold leading-tight">Tax Preparation</h2>
                  <p className="text-[#637588] text-base font-normal leading-normal">
                    Organize tax documents, create tasks for deadlines, and manage submissions.
                  </p>
                  <div className="bg-[#f0f2f4] rounded-lg p-6">
                    <p className="text-[#111418] font-medium">Example Document:</p>
                    <p className="text-[#637588]">Folder of receipts and income statements.</p>
                    <p className="text-[#111418] font-medium mt-4">Ask the Bot:</p>
                    <p className="text-[#637588]">"What expenses can I deduct from these documents?"</p>
                    <p className="text-[#111418] font-medium mt-4">Bot Response:</p>
                    <p className="text-[#637588]">"You have deductible expenses totaling $5,000, which include office supplies, travel, and client meals. Would you like me to generate a report or create a task to prepare your tax documents?"</p>
                  </div>
                </div>

                <div className="flex flex-col gap-6">
                  <h2 className="text-[#111418] text-xl font-bold leading-tight">Project Management</h2>
                  <p className="text-[#637588] text-base font-normal leading-normal">
                    Extract project milestones, assign tasks, and automate project tracking.
                  </p>
                  <div className="bg-[#f0f2f4] rounded-lg p-6">
                    <p className="text-[#111418] font-medium">Example Document:</p>
                    <p className="text-[#637588]">Project plan outlining milestones and deadlines.</p>
                    <p className="text-[#111418] font-medium mt-4">Ask the Bot:</p>
                    <p className="text-[#637588]">“What are the next steps in this project, and who is responsible?”</p>
                    <p className="text-[#111418] font-medium mt-4">Bot Response:</p>
                    <p className="text-[#637588]">“The next milestone is the completion of the design phase, due by October 1, 2024. The responsible team member is Sarah Lee. Would you like to set a reminder or notify Sarah?”</p>
                  </div>
                </div>
                
                <div className="flex flex-col gap-6">
                  <h2 className="text-[#111418] text-xl font-bold leading-tight">Legal Compliance</h2>
                  <p className="text-[#637588] text-base font-normal leading-normal">
                    Ensure documents comply with legal regulations and manage compliance tasks.
                  </p>
                  <div className="bg-[#f0f2f4] rounded-lg p-6">
                    <p className="text-[#111418] font-medium">Example Document:</p>
                    <p className="text-[#637588]">Compliance policy document.</p>
                    <p className="text-[#111418] font-medium mt-4">Ask the Bot:</p>
                    <p className="text-[#637588]">“Does this document comply with the latest GDPR regulations?”</p>
                    <p className="text-[#111418] font-medium mt-4">Bot Response:</p>
                    <p className="text-[#637588]">“This document is mostly compliant, but it's missing a data processing agreement (DPA). Would you like me to draft a task for creating this agreement?”</p>
                  </div>
                </div>
                
                <div className="flex flex-col gap-6">
                  <h2 className="text-[#111418] text-xl font-bold leading-tight">Research & Development</h2>
                  <p className="text-[#637588] text-base font-normal leading-normal">
                    Analyze research documents, extract key findings, and assign tasks for further study.
                  </p>
                  <div className="bg-[#f0f2f4] rounded-lg p-6">
                    <p className="text-[#111418] font-medium">Example Document:</p>
                    <p className="text-[#637588]">Research paper on AI technology.</p>
                    <p className="text-[#111418] font-medium mt-4">Ask the Bot:</p>
                    <p className="text-[#637588]">“Summarize the key findings of this paper.”</p>
                    <p className="text-[#111418] font-medium mt-4">Bot Response:</p>
                    <p className="text-[#637588]">“The paper discusses the latest advancements in AI-driven natural language processing, including a 15% increase in processing speed. Would you like to create tasks for further research or meetings with your R&D team?”</p>
                  </div>
                </div>
                
                <div className="flex flex-col gap-6">
                  <h2 className="text-[#111418] text-xl font-bold leading-tight">Real Estate Management</h2>
                  <p className="text-[#637588] text-base font-normal leading-normal">
                    Manage leases, track payments, and handle tenant communications.
                  </p>
                  <div className="bg-[#f0f2f4] rounded-lg p-6">
                    <p className="text-[#111418] font-medium">Example Document:</p>
                    <p className="text-[#637588]">Lease agreement for a commercial property.</p>
                    <p className="text-[#111418] font-medium mt-4">Ask the Bot:</p>
                    <p className="text-[#637588]">“When is the rent due, and what are the terms for renewal?”</p>
                    <p className="text-[#111418] font-medium mt-4">Bot Response:</p>
                    <p className="text-[#637588]">“The rent is due on the 1st of every month, with a renewal option 60 days before the lease end date of May 31, 2025. Would you like to set a renewal reminder?”</p>
                  </div>
                </div>
                <div className="flex flex-col gap-6">
                  <h2 className="text-[#111418] text-xl font-bold leading-tight">Personal Document Management</h2>
                  <p className="text-[#637588] text-base font-normal leading-normal">
                    Organize personal documents, track important dates, and set reminders.
                  </p>
                  <div className="bg-[#f0f2f4] rounded-lg p-6">
                    <p className="text-[#111418] font-medium">Example Document:</p>
                    <p className="text-[#637588]">Scanned copy of a health insurance policy.</p>
                    <p className="text-[#111418] font-medium mt-4">Ask the Bot:</p>
                    <p className="text-[#637588]">“When does this policy expire, and what's covered?”</p>
                    <p className="text-[#111418] font-medium mt-4">Bot Response:</p>
                    <p className="text-[#637588]">“The policy expires on December 31, 2024, and it covers annual checkups, emergency care, and prescription drugs. Would you like me to set a renewal reminder?”</p>
                  </div>
                </div>
                <div className="flex flex-col gap-6">
                  <h2 className="text-[#111418] text-xl font-bold leading-tight">Travel Planning</h2>
                  <p className="text-[#637588] text-base font-normal leading-normal">
                    Organize travel documents, manage itineraries, and automate bookings.
                  </p>
                  <div className="bg-[#f0f2f4] rounded-lg p-6">
                    <p className="text-[#111418] font-medium">Example Document:</p>
                    <p className="text-[#637588]">Itinerary with flight, hotel, and car rental information.</p>
                    <p className="text-[#111418] font-medium mt-4">Ask the Bot:</p>
                    <p className="text-[#637588]">“What time is my flight, and where am I staying?”</p>
                    <p className="text-[#111418] font-medium mt-4">Bot Response:</p>
                    <p className="text-[#637588]">“Your flight is at 8:00 AM on September 15, 2024, and you're staying at the Hilton Downtown. Would you like me to check you in or book a taxi to the airport?”</p>
                  </div>
                </div>
                <div className="flex flex-col gap-6">
                  <h2 className="text-[#111418] text-xl font-bold leading-tight">Healthcare Management</h2>
                  <p className="text-[#637588] text-base font-normal leading-normal">
                    Manage medical records, track appointments, and follow up on health recommendations.
                  </p>
                  <div className="bg-[#f0f2f4] rounded-lg p-6">
                    <p className="text-[#111418] font-medium">Example Document:</p>
                    <p className="text-[#637588]">Summary of a recent medical visit.</p>
                    <p className="text-[#111418] font-medium mt-4">Ask the Bot:</p>
                    <p className="text-[#637588]">“What were the doctor's recommendations?”</p>
                    <p className="text-[#111418] font-medium mt-4">Bot Response:</p>
                    <p className="text-[#637588]">“The doctor recommended a follow-up in 6 months and a daily vitamin D supplement. Would you like to set a reminder for the follow-up appointment?”</p>
                  </div>
                </div>
                
                <div className="flex flex-col gap-6">
                  <h2 className="text-[#111418] text-xl font-bold leading-tight">Expense Tracking</h2>
                  <p className="text-[#637588] text-base font-normal leading-normal">
                    Organize receipts, track expenses, and prepare expense reports.
                  </p>
                  <div className="bg-[#f0f2f4] rounded-lg p-6">
                    <p className="text-[#111418] font-medium">Example Document:</p>
                    <p className="text-[#637588]">Scanned receipts from a business trip.</p>
                    <p className="text-[#111418] font-medium mt-4">Ask the Bot:</p>
                    <p className="text-[#637588]">“What's the total expense, and are any of these receipts missing details?”</p>
                    <p className="text-[#111418] font-medium mt-4">Bot Response:</p>
                    <p className="text-[#637588]">“The total expense is $2,400. One receipt is missing a vendor name; would you like to create a task to follow up on this?”</p>
                  </div>
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UseCases;
