import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import MobileLayout from '../layout/MobileLayout';
import { getProfile, logout } from '../../../services/endpoints';
import '../../../styles/mobile/profile.scss';

const MobileProfile = () => {
    const [profile, setProfile] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchProfile = async () => {
            try {
                const response = await getProfile();
                setProfile(response.data);
            } catch (err) {
                console.error('Profile fetch error:', err);
                setError(err.message || 'Failed to fetch profile');
            } finally {
                setLoading(false);
            }
        };

        fetchProfile();
    }, []);

    const handleLogout = async () => {
        try {
            // First try to logout on the backend while we still have the token
            await logout();
            
            // If successful, clear local storage and navigate
            localStorage.removeItem('jwt_token');
            navigate('/login');
        } catch (error) {
            console.error('Backend logout failed:', error);
            // Even if backend logout fails, we should still logout locally
            localStorage.removeItem('jwt_token');
            navigate('/login');
        }
    };

    if (loading) {
        return (
            <MobileLayout>
                <div className="mobile-profile">
                    <div className="loading">Loading...</div>
                </div>
            </MobileLayout>
        );
    }

    if (error) {
        return (
            <MobileLayout>
                <div className="mobile-profile">
                    <div className="error">{error}</div>
                </div>
            </MobileLayout>
        );
    }

    return (
        <MobileLayout>
            <div className="mobile-profile">
                <div className="profile-section">
                    <h2>Profile Information</h2>
                    <div className="profile-info">
                        <div className="info-item">
                            <label>Name</label>
                            <div>{profile?.first_name} {profile?.last_name}</div>
                        </div>
                        <div className="info-item">
                            <label>Username</label>
                            <div>{profile?.username}</div>
                        </div>
                        <div className="info-item">
                            <label>Email</label>
                            <div>{profile?.email}</div>
                        </div>
                        <div className="info-item">
                            <label>Goffer Email</label>
                            <div>{profile?.goffer_email}</div>
                        </div>
                    </div>
                </div>
                <button 
                    onClick={handleLogout}
                    className="logout-button"
                >
                    Logout
                </button>
            </div>
        </MobileLayout>
    );
};

export default MobileProfile; 