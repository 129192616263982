import React from 'react';
import { Helmet } from 'react-helmet-async';

function PrivacyPolicy() {
  return (
    <>
      <Helmet>
        <title>Privacy Policy - Goffer.AI | Data Protection & Security</title>
        <meta name="description" content="Learn about Goffer.AI's commitment to protecting your privacy and data security. Our comprehensive privacy policy details how we handle and safeguard your information." />
        <meta name="keywords" content="privacy policy, data protection, GDPR compliance, data security, document privacy, user privacy, information security" />
        
        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Privacy Policy - Goffer.AI | Data Protection & Security" />
        <meta property="og:description" content="Understand how Goffer.AI protects your privacy and ensures the security of your documents and personal information." />
        <meta property="og:image" content="https://goffer.ai/privacy-og.jpg" />
        
        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Privacy Policy - Goffer.AI | Data Protection & Security" />
        <meta name="twitter:description" content="Understand how Goffer.AI protects your privacy and ensures the security of your documents and personal information." />
        <meta name="twitter:image" content="https://goffer.ai/privacy-twitter.jpg" />
        
        {/* Additional SEO */}
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Goffer.AI Team" />
        <link rel="canonical" href="https://goffer.ai/privacy" />
      </Helmet>

      <div className="relative flex size-full min-h-screen flex-col bg-white group/design-root overflow-x-hidden" style={{ fontFamily: 'Inter, "Noto Sans", sans-serif' }}>
        <div className="layout-container flex h-full grow flex-col">
          
          {/* Main Content */}
          <div className="px-40 flex flex-1 justify-center py-5">
            <div className="layout-content-container flex flex-col max-w-[960px] flex-1">
              <div className="flex flex-col gap-10 px-4 py-10">
                <div className="flex flex-col gap-4">
                  <h1 className="text-[#111418] text-[32px] font-bold leading-tight tracking-[-0.033em]">Privacy Policy</h1>
                  <p className="text-[#637588] text-base leading-normal">
                    Last updated: {new Date().toLocaleDateString()}
                  </p>
                </div>

                {/* Introduction */}
                <div className="flex flex-col gap-4">
                  <p className="text-[#111418] text-base leading-normal">
                    At Goffer AI, we take your privacy seriously. This Privacy Policy explains how we collect, use, and protect your information when you use our services.
                  </p>
                </div>

                {/* Key Privacy Commitments */}
                <div className="flex flex-col gap-6">
                  <h2 className="text-[#111418] text-xl font-bold leading-tight">Our Key Privacy Commitments</h2>
                  <div className="bg-[#f0f2f4] rounded-lg p-6">
                    <ul className="list-disc list-inside space-y-4 text-[#111418]">
                      <li>We will never share your data with third parties without your explicit consent</li>
                      <li>We will never use your documents to train our large language models without your explicit consent</li>
                      <li>We will never fine-tune our models on your data without your explicit permission</li>
                      <li>Your data belongs to you, and you maintain full control over it at all times</li>
                    </ul>
                  </div>
                </div>

                {/* Data Collection */}
                <div className="flex flex-col gap-6">
                  <h2 className="text-[#111418] text-xl font-bold leading-tight">Information We Collect</h2>
                  <p className="text-[#637588] text-base leading-normal">
                    We collect only the information necessary to provide our services:
                  </p>
                  <ul className="list-disc list-inside space-y-2 text-[#637588]">
                    <li>Account information (email, name)</li>
                    <li>Documents you upload or share with our service</li>
                    <li>Usage data to improve our service performance</li>
                    <li>Communication preferences</li>
                  </ul>
                </div>

                {/* How We Use Your Information */}
                <div className="flex flex-col gap-6">
                  <h2 className="text-[#111418] text-xl font-bold leading-tight">How We Use Your Information</h2>
                  <p className="text-[#637588] text-base leading-normal">
                    Your information is used exclusively for:
                  </p>
                  <ul className="list-disc list-inside space-y-2 text-[#637588]">
                    <li>Providing our document processing and AI assistant services</li>
                    <li>Improving our service performance and user experience</li>
                    <li>Communicating with you about your account and our services</li>
                    <li>Ensuring the security and reliability of our platform</li>
                  </ul>
                </div>

                {/* Data Security */}
                <div className="flex flex-col gap-6">
                  <h2 className="text-[#111418] text-xl font-bold leading-tight">Data Security</h2>
                  <p className="text-[#637588] text-base leading-normal">
                    We implement robust security measures to protect your data:
                  </p>
                  <ul className="list-disc list-inside space-y-2 text-[#637588]">
                    <li>End-to-end encryption for document storage and transmission</li>
                    <li>Regular security audits and updates</li>
                    <li>Strict access controls and authentication measures</li>
                    <li>Secure data centers with industry-standard protections</li>
                  </ul>
                </div>

                {/* Your Rights */}
                <div className="flex flex-col gap-6">
                  <h2 className="text-[#111418] text-xl font-bold leading-tight">Your Rights</h2>
                  <p className="text-[#637588] text-base leading-normal">
                    You have the right to:
                  </p>
                  <ul className="list-disc list-inside space-y-2 text-[#637588]">
                    <li>Access your personal data</li>
                    <li>Request deletion of your data</li>
                    <li>Export your data</li>
                    <li>Opt-out of any data processing beyond basic service provision</li>
                    <li>Withdraw consent at any time</li>
                  </ul>
                </div>

                {/* Contact Information */}
                <div className="flex flex-col gap-6">
                  <h2 className="text-[#111418] text-xl font-bold leading-tight">Contact Us</h2>
                  <p className="text-[#637588] text-base leading-normal">
                    If you have any questions about our Privacy Policy or data practices, please contact us at:
                  </p>
                  <div className="bg-[#f0f2f4] rounded-lg p-6">
                    <p className="text-[#111418]">Email: privacy@goffer.ai</p>
                    <p className="text-[#111418]">Address: [Your Company Address]</p>
                  </div>
                </div>

                {/* Updates to Privacy Policy */}
                <div className="flex flex-col gap-6">
                  <h2 className="text-[#111418] text-xl font-bold leading-tight">Updates to This Policy</h2>
                  <p className="text-[#637588] text-base leading-normal">
                    We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Last updated" date at the top of this Policy.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PrivacyPolicy;

