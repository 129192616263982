import React, { useState } from 'react';
import MobileLayout from '../layout/MobileLayout';
import { searchFiles, getFileUrl } from '../../../services/endpoints';
import '../../../styles/mobile/search.scss';

const FileViewerPanel = ({ url, onClose }) => {
  if (!url) return null;
  
  return (
    <div className="file-viewer-overlay">
      <div className="file-viewer-panel">
        <button className="close-button" onClick={onClose}>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/>
          </svg>
        </button>
        <iframe src={url} title="File Preview" />
      </div>
    </div>
  );
};

const MobileSearch = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [error, setError] = useState(null);
  const [fileUrl, setFileUrl] = useState(null);

  const handleSearch = async (e) => {
    e.preventDefault();
    if (!searchQuery.trim()) return;

    setIsSearching(true);
    setError(null); // Clear any previous errors
    try {
      const response = await searchFiles(searchQuery, 10);
      
      if (response.data.success) {
        console.log('Search response:', response.data.results);
        
        const transformedResults = response.data.results.map((result, index) => ({
          id: index,
          fileId: result.document.file_id,
          name: result.document.label || 'Untitled Document',
          snippet: result.text,
          type: result.meta?.source?.document_type || 'Unknown',
          vendor: result.document.metadata?.vendor || 'Unknown',
          date: new Date().toLocaleDateString(),
          score: result.score
        }));
        setSearchResults(transformedResults);
      } else {
        setSearchResults([]);
        setError('No results found');
      }
    } catch (error) {
      console.error('Search failed:', error);
      setSearchResults([]);
      setError('Failed to search documents. Please try again.');
    } finally {
      setIsSearching(false);
    }
  };

  const handleResultClick = async (result) => {
    try {
      const fileId = result.fileId;
      
      if (!fileId) {
        console.error('No file ID found in result:', result);
        setError('Unable to view this document');
        return;
      }
      
      const { url } = await getFileUrl(fileId);
      setFileUrl(url);
    } catch (error) {
      console.error('Error viewing file:', error);
      setError('Failed to open document. Please try again.');
    }
  };

  return (
    <MobileLayout>
      <div className="mobile-search">
        <form onSubmit={handleSearch} className="search-form">
          <div className="search-input-container">
            <svg className="search-icon" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
              <path d="M15.5 14h-.79l-.28-.27a6.5 6.5 0 0 0 1.48-5.34c-.47-2.78-2.79-5-5.59-5.34a6.505 6.505 0 0 0-7.27 7.27c.34 2.8 2.56 5.12 5.34 5.59a6.5 6.5 0 0 0 5.34-1.48l.27.28v.79l4.25 4.25c.41.41 1.08.41 1.49 0 .41-.41.41-1.08 0-1.49L15.5 14zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"/>
            </svg>
            <input
              type="text"
              placeholder="Search through your documents..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="search-input"
            />
            {searchQuery && (
              <button
                type="button"
                className="clear-button"
                onClick={() => setSearchQuery('')}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                  <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/>
                </svg>
              </button>
            )}
          </div>
        </form>

        {error && (
          <div className="error-state">
            {error}
          </div>
        )}

        <div className="search-results">
          {isSearching ? (
            <div className="loading-state">Searching...</div>
          ) : searchResults.length > 0 ? (
            <div className="results-list">
              {searchResults.map((result) => (
                <div 
                  key={result.id} 
                  className="result-item"
                  onClick={() => handleResultClick(result)}
                >
                  <div className="result-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                      <path d="M14 2H6c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zM6 20V4h7v5h5v11H6z"/>
                    </svg>
                  </div>
                  <div className="result-content">
                    <div className="result-header">
                      <h3>{result.name}</h3>
                      <span className="result-score">{(result.score * 100).toFixed(1)}% match</span>
                    </div>
                    <p className="result-snippet">{result.snippet}</p>
                    <div className="result-meta">
                      <span>{result.type}</span>
                      <span>•</span>
                      <span>{result.date}</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : searchQuery ? (
            <div className="empty-state">No results found</div>
          ) : (
            <div className="initial-state">
              <div className="search-illustration">
                <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 24 24">
                  <path d="M15.5 14h-.79l-.28-.27a6.5 6.5 0 0 0 1.48-5.34c-.47-2.78-2.79-5-5.59-5.34a6.505 6.505 0 0 0-7.27 7.27c.34 2.8 2.56 5.12 5.34 5.59a6.5 6.5 0 0 0 5.34-1.48l.27.28v.79l4.25 4.25c.41.41 1.08.41 1.49 0 .41-.41.41-1.08 0-1.49L15.5 14zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"/>
                </svg>
              </div>
              <p>Search through your documents</p>
            </div>
          )}
        </div>

        <FileViewerPanel 
          url={fileUrl} 
          onClose={() => setFileUrl(null)} 
        />
      </div>
    </MobileLayout>
  );
};

export default MobileSearch; 