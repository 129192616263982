import React from 'react';
import { Link } from 'react-router-dom';

const Blog = () => {
  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-8 text-left">Goffer.AI Blog</h1>
      
      <div className="space-y-8">
        {/* Latest Blog Post */}
        <article className="border border-[#f0f2f4] rounded-xl p-6 hover:shadow-lg transition-shadow">
          <h2 className="text-2xl font-bold mb-2 text-left">
            <Link to="/blog/enhanced-notification-system" className="text-[#111418] hover:text-[#3498db]">
              Enhanced Notification System: Smarter Document Management
            </Link>
          </h2>
          <div className="text-[#637588] mb-4 text-left">
            January 21, 2024 • 5 min read
          </div>
          <p className="text-[#111418] mb-4 text-left">
            We're excited to announce significant improvements to our notification system, making document management smarter and more efficient than ever!
          </p>
          <div className="flex flex-wrap gap-2 text-left">
            <span className="px-3 py-1 bg-[#f0f2f4] text-[#637588] rounded-full text-sm">notifications</span>
            <span className="px-3 py-1 bg-[#f0f2f4] text-[#637588] rounded-full text-sm">updates</span>
            <span className="px-3 py-1 bg-[#f0f2f4] text-[#637588] rounded-full text-sm">features</span>
          </div>
        </article>

        {/* More posts will be added here */}
      </div>
    </div>
  );
};

export default Blog; 