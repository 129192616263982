import React, { forwardRef, useImperativeHandle, useState } from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import '../styles/FileSearch.css';
import { searchFiles } from '../services/endpoints';
import { trackEvent } from '../utils/analytics';

const FileSearch = forwardRef(({ isOpen, onClose, style }, ref) => {
  const [isFilterPanelOpen, setIsFilterPanelOpen] = useState(true);
  const [filters, setFilters] = useState({
    vendor: '',           
    dateRange: null,      
    fileType: '',         
    uploadedBy: 'Me',     
    uploadedDate: null,   
    uploadedVia: 'All'    
  });
  
  const [searchResults, setSearchResults] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useImperativeHandle(ref, () => ({
    setIsFilterPanelOpen: (value) => setIsFilterPanelOpen(value)
  }));

  // Main search function
  const handleSearch = async () => {
    console.log('Search initiated with query:', searchQuery);
    try {
      setIsLoading(true);
      setError(null);
      
      // Track search initiated
      trackEvent('search_submitted', { 
        query_length: searchQuery.length,
        has_filters: Object.keys(filters).some(key => filters[key]),
        filter_types: Object.keys(filters).filter(key => filters[key])
      });
      
      // Include the vendor field and conditionally include the fileType and uploaded_via fields
      const filtersToSend = {};
      if (filters.vendor) {
        filtersToSend.vendor = filters.vendor;
      }
      if (filters.fileType && filters.fileType !== 'All Types') {
        filtersToSend.file_type = filters.fileType.toLowerCase();
      }
      if (filters.uploadedVia && filters.uploadedBy !== 'All') {
        filtersToSend.uploaded_via = filters.uploadedVia;
      }
      if (filters.uploadedBy && filters.uploadedBy !== 'All') {
        filtersToSend.uploaded_by = filters.uploadedBy;
      }

      if (searchQuery.trim()) {
        console.log('Calling searchFiles API with filters:', filtersToSend);
        const response = await searchFiles(searchQuery, 10, filtersToSend);
        console.log('API Response received:', response);
        
        if (response.data.success) {
          console.log('Processing successful response...');
          const transformedResults = response.data.results.map((result, index) => {
            console.log('Processing result:', result);
            return {
              id: index,
              name: result.document.label || 'Untitled Document',
              snippet: result.text,
              type: result.document.file_type || 'Unknown',
              icon: getFileIcon(result.document.file_type),
              size: 'N/A',
              uploadedBy: 'Me',
              uploadedDate: new Date().toISOString(),
              uploadedVia: 'web',
              vendor: result.document.metadata?.vendor || 'Unknown',
              score: result.score,
              url: result.document?.file_url || null
            };
          });

          console.log('Transformed results:', transformedResults);
          setSearchResults(transformedResults);
          
          // Track search results
          trackEvent('search_results', {
            query_length: searchQuery.length,
            results_count: transformedResults.length,
            has_filters: Object.keys(filters).some(key => filters[key]),
            filter_types: Object.keys(filters).filter(key => filters[key])
          });
        } else {
          setError('Search failed: ' + response.data.message);
          setSearchResults([]);
          
          // Track search error
          trackEvent('search_error', {
            error_message: response.data.message,
            query_length: searchQuery.length
          });
        }
      } else {
        console.log('Empty search query');
        setSearchResults([]);
      }
    } catch (error) {
      console.error('Search error:', error);
      setError('An error occurred while searching. Please try again.');
      setSearchResults([]);
      
      // Track search error
      trackEvent('search_error', {
        error_type: error.response?.data?.error || 'unknown_error',
        query_length: searchQuery.length
      });
    } finally {
      setIsLoading(false);
    }
  };

  // Helper function to get appropriate icon for file types
  const getFileIcon = (fileType) => {
    switch (fileType) {
      case 'application/pdf':
        return 'far fa-file-pdf';
      case 'application/msword':
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        return 'far fa-file-word';
      case 'application/vnd.ms-excel':
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        return 'far fa-file-excel';
      case 'image/png':
      case 'image/jpeg':
        return 'far fa-file-image';
      default:
        return 'far fa-file';
    }
  };

  const handleFileOpen = (url, file) => {
    console.log('Attempting to open URL:', url);
    if (url && url !== '#') {
      trackEvent('search_result_click', {
        file_type: file.type,
        result_position: file.id,
        query_length: searchQuery.length
      });
      window.open(url, '_blank', 'noopener,noreferrer');
    }
  };

  // Render different content states (loading, empty, error, or results)
  const renderContent = () => {
    if (isLoading) {
      return (
        <div className="loading-state">
          <i className="fas fa-spinner fa-spin"></i>
          <p>Searching documents...</p>
        </div>
      );
    }

    if (error) {
      return (
        <div className="error-state">
          <p>{error}</p>
        </div>
      );
    }

    if (searchResults.length === 0) {
      return (
        <div className="empty-state">
          <p>No documents found matching your search criteria.</p>
        </div>
      );
    }

    return (
      <div className="files-list">
        {searchResults.map(file => (
          <div key={file.id} className="file-item">
            <div className="file-icon">
              <i className={file.icon}></i>
            </div>
            <div className="file-details">
              <h3>{file.name}</h3>
              <p>Relevance Score: {(file.score * 100).toFixed(1)}%</p>
              <p>Uploaded by: {file.uploadedBy} on {new Date(file.uploadedDate).toLocaleDateString()} via {file.uploadedVia}</p>
              <p>Created by: {file.vendor}</p>
              <p className="file-snippet">{file.snippet}</p>
            </div>
            <div className="file-meta">
              <span className="file-size">{file.size}</span>
              <button 
                className="open-file-btn"
                onClick={() => {
                  console.log('Button clicked for file:', file);
                  handleFileOpen(file.url, file);
                }}
                title="Open file in new window"
                style={{ cursor: 'pointer' }}
              >
                <i className="fas fa-external-link-alt"></i>
              </button>
            </div>
          </div>
        ))}
      </div>
    );
  };

  // Modify handleFilterPanelClose to only handle the filter panel
  const handleFilterPanelClose = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsFilterPanelOpen(false);
    // Main panel stays as-is, don't call onClose()
  };

  return (
    <div style={style}>
      {/* Filter panel */}
      <div className={`filter-panel ${isFilterPanelOpen ? 'open' : ''}`}>
        <div className="filter-header">
          <h3>Search Filters</h3>
          <button 
            onClick={handleFilterPanelClose} 
            className="close-btn"
          >×</button>
        </div>

        {/* Filter options */}
        <div className="filter-content">
          {/* Individual filter sections */}
          <div className="filter-section">
            <label>Created by (vendor name)</label>
            <input
              type="text"
              value={filters.vendor}
              onChange={(e) => setFilters(prev => ({ ...prev, vendor: e.target.value }))}
              placeholder="Type vendor name"
            />
          </div>

          <div className="filter-section">
            <label>Date Range</label>
            <DatePicker
              selected={filters.dateRange}
              onChange={(date) => setFilters(prev => ({ ...prev, dateRange: date }))}
              placeholderText="Select date range"
            />
          </div>

          <div className="filter-section">
            <label>File Type</label>
            <select
              value={filters.fileType}
              onChange={(e) => setFilters(prev => ({ ...prev, fileType: e.target.value }))}
            >
              <option value="">All Types</option>
              <option value="PDF">PDF</option>
              <option value="Word">Word</option>
              <option value="Excel">Excel</option>
              <option value="PNG">PNG</option>
            </select>
          </div>

          <div className="filter-section">
            <label>Uploaded by</label>
            <select
              value={filters.uploadedBy}
              onChange={(e) => setFilters(prev => ({ ...prev, uploadedBy: e.target.value }))}
            >
              <option value="Me">Me</option>
              <option value="All">All</option>
            </select>
          </div>

          <div className="filter-section">
            <label>Uploaded via</label>
            <select
              value={filters.uploadedVia}
              onChange={(e) => setFilters(prev => ({ ...prev, uploadedVia: e.target.value }))}
            >
              <option value="All">All</option>
              <option value="email">Email</option>
              <option value="whatsapp">WhatsApp</option>
              <option value="web">Web</option>
            </select>
          </div>
        </div>
      </div>

      {/* Main content */}
      <div className="dashboard-content">
        <div className="content-wrapper">
          <div className="main-content">
            <h1 className="welcome-text">All files</h1>
            
            {/* Search input */}
            <div className="chat-input-container">
              <input
                type="text"
                placeholder="Search's file by concept, keyword or a phrase"
                className="chat-input"
                value={searchQuery}
                onChange={(e) => {
                  console.log('Search query changed:', e.target.value);
                  setSearchQuery(e.target.value);
                }}
                onKeyPress={(e) => {
                  console.log('Key pressed in search input:', e.key);
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    console.log('Enter key pressed, triggering search with query:', searchQuery);
                    handleSearch();
                  }
                }}
              />
              <button 
                className="chat-button"
                onClick={() => {
                  console.log('Search button clicked with query:', searchQuery);
                  handleSearch();
                }}
              >
                <i className="fas fa-search"></i> Search Files
              </button>
            </div>
            
            {/* Active filter tags */}
            <div className="filter-tags">
              {filters.vendor && (
                <span className="filter-tag">
                  {filters.vendor} 
                  <button onClick={() => setFilters(prev => ({ ...prev, vendor: '' }))}>×</button>
                </span>
              )}
              {filters.fileType && (
                <span className="filter-tag">
                  {filters.fileType} 
                  <button onClick={() => setFilters(prev => ({ ...prev, fileType: '' }))}>×</button>
                </span>
              )}
              {filters.uploadedVia && (
                <span className="filter-tag">
                  {filters.uploadedVia} 
                  <button onClick={() => setFilters(prev => ({ ...prev, uploadedVia: '' }))}>×</button>
                </span>
              )}
              {filters.uploadedBy && filters.uploadedBy !== 'All' && (
                <span className="filter-tag">
                  {filters.uploadedBy} 
                  <button onClick={() => setFilters(prev => ({ ...prev, uploadedBy: 'All' }))}>×</button>
                </span>
              )}
            </div>

            {/* Results section */}
            <div className="file-content">
              <div className="files-count">
                {searchResults.length} Files
              </div>

              {renderContent()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

// Add display name for debugging
FileSearch.displayName = 'FileSearch';

export default FileSearch; 