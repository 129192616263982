import React, { useEffect, useState } from 'react';
import { 
  createBrowserRouter,
  RouterProvider,
  useNavigate,
  useLocation,
  Navigate,
  Outlet
} from 'react-router-dom';
import Layout from './components/Layout';
import Home from './components/Home';
import Features from './components/Features';
import UseCases from './components/UseCases';
import PrivacyPolicy from './components/PrivacyPolicy';
import Login from './components/Login';
import Register from './components/Register';
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/ResetPassword';
import Dashboard from './components/Dashboard';
import Onboarding from './components/Onboarding';
import ProtectedRoute from './components/ProtectedRoute';
import './styles/global.css';
import { GoogleOAuthProvider } from '@react-oauth/google';
import RemindersManagement from './components/RemindersManagement';
import ErrorBoundary from './components/ErrorBoundary';
import { initGA, usePageTracking } from './utils/analytics';
import Terms from './components/Terms';
import Blog from './components/Blog';
import BlogPost from './components/BlogPost';
import { HelmetProvider } from 'react-helmet-async';

// Import mobile components
import MobileHome from './components/mobile/home/MobileHome';
import MobileChat from './components/mobile/chat/MobileChat';
import MobileFiles from './components/mobile/files/MobileFiles';
import MobileReminders from './components/mobile/reminders/MobileReminders';
import MobileSearch from './components/mobile/search/MobileSearch';
import MobileProfile from './components/mobile/profile/MobileProfile';
import MobileOnboarding from './components/mobile/onboarding/MobileOnboarding';

// Add viewport hook
const useViewport = () => {
  const [width, setWidth] = useState(window.innerWidth);
  
  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  return { width };
};

const getIsAuthenticated = () => !!localStorage.getItem('jwt_token');

// Add route wrapper component
const RouteWrapper = ({ desktop, mobile }) => {
  const { width } = useViewport();
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = width <= 768;

  useEffect(() => {
    const currentPath = location.pathname;
    const isMobilePath = currentPath.startsWith('/m');
    const isAuth = getIsAuthenticated();
    const isProtectedPath = ['/dashboard', '/onboarding', '/reminders'].some(path => currentPath.startsWith(path));
    
    // Skip redirection for homepage
    if (currentPath === '/') {
      return;
    }
    
    // Only redirect to mobile for protected routes
    if (isAuth && isMobile && isProtectedPath) {
      const mobilePath = currentPath === '/dashboard' ? '/m' : `/m${currentPath.replace('/dashboard', '')}`;
      navigate(mobilePath, { replace: true });
    } else if (isAuth && !isMobile && isMobilePath) {
      navigate('/dashboard', { replace: true });
    }
  }, [width, location.pathname, navigate, isMobile]);

  return isMobile ? mobile : desktop;
};

// Create Analytics wrapper component
function AnalyticsWrapper() {
  usePageTracking();
  return <Outlet />;
}

const router = createBrowserRouter([
  {
    element: (
      <HelmetProvider>
        <AnalyticsWrapper />
      </HelmetProvider>
    ),
    children: [
      {
        path: "/",
        element: <RouteWrapper 
          desktop={<Layout><Home /></Layout>}
          mobile={getIsAuthenticated() ? <ProtectedRoute><MobileHome /></ProtectedRoute> : <Layout><Home /></Layout>}
        />
      },
      {
        path: "/features",
        element: <RouteWrapper 
          desktop={<Layout><Features /></Layout>}
          mobile={<Layout><Features /></Layout>}
        />
      },
      {
        path: "/use-cases",
        element: <RouteWrapper 
          desktop={<Layout><UseCases /></Layout>}
          mobile={<Layout><UseCases /></Layout>}
        />
      },
      {
        path: "/privacy",
        element: <RouteWrapper 
          desktop={<Layout><PrivacyPolicy /></Layout>}
          mobile={<Layout><PrivacyPolicy /></Layout>}
        />
      },
      {
        path: "/login",
        element: <RouteWrapper 
          desktop={<Layout><Login /></Layout>}
          mobile={<Layout><Login /></Layout>}
        />
      },
      {
        path: "/register",
        element: <RouteWrapper 
          desktop={<Layout><Register /></Layout>}
          mobile={<Layout><Register /></Layout>}
        />
      },
      {
        path: "/forgot-password",
        element: <RouteWrapper 
          desktop={<Layout><ForgotPassword /></Layout>}
          mobile={<Layout><ForgotPassword /></Layout>}
        />
      },
      {
        path: "/reset-password",
        element: <RouteWrapper 
          desktop={<Layout><ResetPassword /></Layout>}
          mobile={<Layout><ResetPassword /></Layout>}
        />
      },
      {
        path: "/dashboard/*",
        element: <RouteWrapper 
          desktop={<ProtectedRoute><Layout><Dashboard /></Layout></ProtectedRoute>}
          mobile={<Navigate to="/m" replace />}
        />
      },
      {
        path: "/onboarding",
        element: <RouteWrapper 
          desktop={<ProtectedRoute><Layout><Onboarding /></Layout></ProtectedRoute>}
          mobile={<Navigate to="/m/onboarding" replace />}
        />
      },
      {
        path: "/reminders",
        element: <RouteWrapper 
          desktop={<ProtectedRoute><Layout><RemindersManagement /></Layout></ProtectedRoute>}
          mobile={<Navigate to="/m/reminders" replace />}
        />
      },
      {
        path: "/terms",
        element: <Terms />
      },
      {
        path: "/blog",
        element: <RouteWrapper 
          desktop={<Layout><Blog /></Layout>}
          mobile={<Layout><Blog /></Layout>}
        />
      },
      {
        path: "/blog/:slug",
        element: <RouteWrapper 
          desktop={<Layout><BlogPost /></Layout>}
          mobile={<Layout><BlogPost /></Layout>}
        />
      },
      {
        path: "/m",
        element: <RouteWrapper 
          desktop={<Navigate to="/dashboard" replace />}
          mobile={<ProtectedRoute><MobileHome /></ProtectedRoute>}
        />
      },
      {
        path: "/m/chat",
        element: <RouteWrapper 
          desktop={<Navigate to="/dashboard" replace />}
          mobile={<ProtectedRoute><MobileChat /></ProtectedRoute>}
        />
      },
      {
        path: "/m/chat/:chatId",
        element: <RouteWrapper 
          desktop={<Navigate to="/dashboard" replace />}
          mobile={<ProtectedRoute><MobileChat /></ProtectedRoute>}
        />
      },
      {
        path: "/m/files",
        element: <RouteWrapper 
          desktop={<Navigate to="/dashboard" replace />}
          mobile={<ProtectedRoute><MobileFiles /></ProtectedRoute>}
        />
      },
      {
        path: "/m/reminders",
        element: <RouteWrapper 
          desktop={<Navigate to="/reminders" replace />}
          mobile={<ProtectedRoute><MobileReminders /></ProtectedRoute>}
        />
      },
      {
        path: "/m/search",
        element: <RouteWrapper 
          desktop={<Navigate to="/dashboard" replace />}
          mobile={<ProtectedRoute><MobileSearch /></ProtectedRoute>}
        />
      },
      {
        path: "/m/profile",
        element: <RouteWrapper 
          desktop={<Navigate to="/dashboard" replace />}
          mobile={<ProtectedRoute><MobileProfile /></ProtectedRoute>}
        />
      },
      {
        path: "/m/dashboard",
        element: <RouteWrapper 
          desktop={<Navigate to="/dashboard" replace />}
          mobile={<ProtectedRoute><MobileHome /></ProtectedRoute>}
        />
      },
      {
        path: "/m/onboarding",
        element: <RouteWrapper 
          desktop={<Navigate to="/onboarding" replace />}
          mobile={<ProtectedRoute><MobileOnboarding /></ProtectedRoute>}
        />
      }
    ]
  }
], {
  future: {
    v7_startTransition: true,
    v7_relativeSplatPath: true,
    v7_fetcherPersist: true,
    v7_normalizeFormMethod: true,
    v7_partialHydration: true,
    v7_skipActionErrorRevalidation: true,
    v7_prependBasename: true
  },
  basename: process.env.PUBLIC_URL || '/',
  window: typeof window === 'undefined' ? undefined : window
});

function App() {
  const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
  
  useEffect(() => {
    if (process.env.REACT_APP_GA_MEASUREMENT_ID) {
      initGA();
    }
  }, []);

  return (
    <ErrorBoundary>
      <GoogleOAuthProvider clientId={googleClientId}>
        <RouterProvider router={router} />
      </GoogleOAuthProvider>
    </ErrorBoundary>
  );
}

export default App;