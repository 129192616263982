import React from 'react';
import { useLocation } from 'react-router-dom';
import Navbar from './Navbar';
import AuthenticatedNavbar from './AuthenticatedNavbar';
import Footer from './Footer';

function Layout({ children }) {
  const location = useLocation();
  const isAuthenticated = !!localStorage.getItem('jwt_token');
  const isDashboard = location.pathname.includes('/dashboard');

  return (
    <div className="flex flex-col min-h-screen w-full bg-white" style={{fontFamily: 'Inter, "Noto Sans", sans-serif'}}>
      {isAuthenticated ? <AuthenticatedNavbar /> : <Navbar />}
      <main className={`flex-grow w-full ${isDashboard ? 'dashboard-layout' : ''}`}>
        {children}
      </main>
      <Footer />
    </div>
  );
}

export default Layout;
