import { uploadFile } from '../services/endpoints';

/**
 * Processes a message and handles any file uploads
 * @param {string|object} messageData - The message data to process
 * @returns {object} - Processed message data with uploaded files
 */
export const processMessage = async (messageData) => {
  // Handle both string messages and message objects
  const message = typeof messageData === 'string' ? { content: messageData, type: 'text' } : messageData;
  let uploadedFiles = [];

  // Handle file upload if present
  if (message.file) {
    try {
      const formData = new FormData();
      formData.append('file', message.file);
      
      console.log('Uploading file...');
      const uploadResult = await uploadFile(formData);
      console.log('File upload result:', uploadResult);
      
      if (uploadResult.data.success && uploadResult.data.files && uploadResult.data.files.length > 0) {
        const uploadedFile = uploadResult.data.files[0];
        uploadedFiles = [{
          id: uploadedFile.id,
          s3_url: uploadedFile.s3_url,
          filename: uploadedFile.filename,
          file_type: uploadedFile.file_type || message.type,
          vellum_file_id: uploadedFile.vellum_file_id,
          presigned_url: uploadedFile.presigned_url
        }];
      }
    } catch (error) {
      console.error('Error uploading file:', error);
      throw error;
    }
  }

  // If there's a file but no content, set a default message
  const finalContent = (!message.content || !message.content.trim()) && uploadedFiles.length > 0 
    ? '' // Empty content for file-only messages
    : message.content || '';

  return {
    message: {
      content: finalContent,
      type: message.type || 'text',
      file: uploadedFiles.length > 0 ? uploadedFiles[0] : null
    },
    files: uploadedFiles
  };
}; 