import React, { useState, useEffect } from 'react';
import { getNotifications, updateNotification } from '../../../services/endpoints';
import MobileLayout from '../layout/MobileLayout';
import '../../../styles/mobile/reminders.scss';

const MobileReminders = () => {
  const [reminders, setReminders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchReminders();
  }, []);

  const fetchReminders = async () => {
    try {
      setLoading(true);
      const response = await getNotifications();
      
      const notificationsAsReminders = response.data.notifications.map(notification => ({
        id: notification.id,
        title: notification.title,
        completed: notification.status === 'COMPLETED'
      }));

      setReminders(notificationsAsReminders);
      setError(null);
    } catch (err) {
      console.error('Error fetching reminders:', err);
      setError('Failed to load reminders');
    } finally {
      setLoading(false);
    }
  };

  const handleToggleComplete = async (id) => {
    try {
      const reminder = reminders.find(r => r.id === id);
      const newStatus = reminder.completed ? 'PENDING' : 'COMPLETED';
      await updateNotification(id, { status: newStatus });
      
      setReminders(reminders.map(r => 
        r.id === id ? { ...r, completed: !r.completed } : r
      ));
    } catch (err) {
      console.error('Error updating reminder:', err);
    }
  };

  return (
    <MobileLayout>
      <div className="mobile-reminders">
        {loading ? (
          <div className="loading-state">Loading reminders...</div>
        ) : error ? (
          <div className="error-state">{error}</div>
        ) : (
          <div className="tasks-list">
            {reminders.map(reminder => (
              <div key={reminder.id} className={`task-item ${reminder.completed ? 'completed' : ''}`}>
                <input
                  type="radio"
                  checked={reminder.completed}
                  onChange={() => handleToggleComplete(reminder.id)}
                />
                <span className="task-title">{reminder.title}</span>
              </div>
            ))}
          </div>
        )}
      </div>
    </MobileLayout>
  );
};

export default MobileReminders; 