import React, { useState, useEffect, useRef } from 'react';
import { getNotifications, updateNotification } from '../services/endpoints';
import { useNavigate } from 'react-router-dom';
import { trackEvent } from '../utils/analytics';

function NotificationsDropdown({ isOpen, onClose, onNotificationUpdate }) {
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const dropdownRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const response = await getNotifications();
        const notificationsArray = Array.isArray(response.data) 
          ? response.data 
          : Array.isArray(response.data?.notifications) 
            ? response.data.notifications 
            : [];
            
        // Filter to show only pending notifications
        const newNotifications = notificationsArray.filter(
          notification => notification.status === 'PENDING'
        );
            
        setNotifications(newNotifications);
        
        // Track when notifications dropdown is opened - moved here after we have the notifications
        if (isOpen) {
          trackEvent('notifications_dropdown_open', {
            notification_count: newNotifications.length,
            has_pending: newNotifications.some(n => n.status === 'PENDING')
          });
        }
        
        setLoading(false);
        setError(null);
      } catch (error) {
        console.error('Error fetching notifications:', error);
        setNotifications([]);
        setLoading(false);
        setError('Failed to load notifications');
      }
    };

    if (isOpen) {
      fetchNotifications();
    }
  }, [isOpen]);
  
  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, onClose]);

  const handleNotificationClick = async (notificationId) => {
    try {
      // Track notification click
      const clickedNotification = notifications.find(n => n.id === notificationId);
      trackEvent('notification_click', {
        notification_id: notificationId,
        notification_type: clickedNotification.type,
        notification_priority: clickedNotification.priority,
        time_to_click: new Date() - new Date(clickedNotification.created_at)
      });

      // Mark as acknowledged by updating status
      await updateNotification(notificationId, { 
        status: 'ACKNOWLEDGED'
      });
      
      // Track status change
      trackEvent('notification_status_change', {
        notification_id: notificationId,
        old_status: 'PENDING',
        new_status: 'ACKNOWLEDGED',
        notification_type: clickedNotification.type
      });
      
      // Update local state to remove the clicked notification
      setNotifications(prevNotifications => {
        console.log('Previous notifications:', prevNotifications);
        const filtered = prevNotifications.filter(notification => notification.id !== notificationId);
        console.log('Updated notifications:', filtered);
        return filtered;
      });
      
      // Force a refresh of the unread count
      if (onNotificationUpdate) {
        onNotificationUpdate();
      }
      
      // Find the clicked notification and format it for the reminders view
      const reminderFormat = {
        id: clickedNotification.id,
        title: clickedNotification.title,
        completed: clickedNotification.status === 'COMPLETED',
        date: new Date(clickedNotification.created_at).toLocaleDateString(),
        category: clickedNotification.type,
        priority: clickedNotification.priority,
        status: 'ACKNOWLEDGED',  // Set status to ACKNOWLEDGED
        original: {
          ...clickedNotification,
          description: clickedNotification.description || '',
          file_info: clickedNotification.file_info || null,
          viewed: true,
          status: 'ACKNOWLEDGED'
        }
      };

      // Track navigation to reminders
      trackEvent('notification_to_reminder_navigation', {
        notification_id: notificationId,
        notification_type: clickedNotification.type
      });

      navigate('/reminders', { 
        state: { selectedNotification: reminderFormat }
      });
      onClose();
    } catch (error) {
      console.error('Error marking notification as acknowledged:', error);
      
      // Track error
      trackEvent('notification_error', {
        notification_id: notificationId,
        error_message: error.message,
        action: 'acknowledge'
      });
    }
  };

  if (!isOpen) return null;

  return (
    <div 
      ref={dropdownRef}
      className={`absolute right-4 mt-3 w-[28rem] max-w-[90vw] bg-white rounded-lg shadow-lg border border-gray-200 z-50 ${
        isOpen ? 'block' : 'hidden'
      }`}
    >
      <div className="max-h-[80vh] overflow-y-auto overflow-x-hidden">
        <div className="sticky top-0 bg-white border-b border-gray-200 px-6 py-4">
          <h3 className="text-lg font-semibold">Notifications</h3>
        </div>

        {loading ? (
          <div className="px-6 py-4 text-center text-gray-500">
            Loading notifications...
          </div>
        ) : error ? (
          <div className="px-6 py-4 text-center text-red-500">
            {error}
          </div>
        ) : notifications.length === 0 ? (
          <div className="px-6 py-4 text-center text-gray-500">
            No notifications
          </div>
        ) : (
          <div className="divide-y divide-gray-100">
            {notifications.map((notification) => (
              <div 
                key={notification.id} 
                onClick={() => handleNotificationClick(notification.id)}
                className="px-6 py-4 hover:bg-gray-50 cursor-pointer transition-colors duration-150 ease-in-out"
                role="button"
                tabIndex={0}
                onKeyPress={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    handleNotificationClick(notification.id);
                  }
                }}
              >
                <div>
                  <div className="font-medium text-gray-900 mb-1 break-words">
                    {notification.title}
                  </div>
                  {notification.description && (
                    <p className="text-sm text-gray-600 break-words whitespace-normal pr-4">
                      {notification.description}
                    </p>
                  )}
                  <div className="flex items-center justify-between mt-2 flex-wrap gap-2">
                    <span className={`text-xs px-2 py-1 rounded-full flex-shrink-0 ${
                      notification.priority === 'HIGH' ? 'bg-red-100 text-red-800' :
                      notification.priority === 'MEDIUM' ? 'bg-yellow-100 text-yellow-800' :
                      'bg-green-100 text-green-800'
                    }`}>
                      {notification.priority}
                    </span>
                    <span className="text-xs text-gray-500">
                      {new Date(notification.created_at).toLocaleDateString()}
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default NotificationsDropdown; 