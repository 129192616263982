import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { getUserProfile } from '../services/endpoints';
import UploadModal from './UploadModal';
import LeftMenu from './LeftMenu';
import Query from './Query';
import ChatSidebar from './ChatSidebar';
import FileSearch from './FileSearch';
import '../styles/Dashboard.css';
import '../styles/DashboardMobile.css';
import { createChat } from '../services/endpoints';
import { trackEvent } from '../utils/analytics';

const useViewport = () => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  return { width };
};

const Dashboard = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { width } = useViewport();
  const isMobile = width <= 768;

  useEffect(() => {
    if (location.pathname === '/' && !isMobile) {
      navigate('/dashboard', { replace: true });
    }
  }, [isMobile, navigate, location]);

  const [profile, setProfile] = useState({
    first_name: '',
    last_name: '',
    email: '',
    goffer_email: ''
  });
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [showChat, setShowChat] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState('');
  const [isChatSidebarOpen, setIsChatSidebarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [chatId, setChatId] = useState(null);
  const [initialResponse, setInitialResponse] = useState(null);
  const [selectedChat, setSelectedChat] = useState(null);
  const [isFileSearchOpen, setIsFileSearchOpen] = useState(false);
  const fileSearchRef = useRef(null);
  const inputRef = useRef(null);

  const suggestionTemplates = [
    {
      title: "Document Search",
      examples: [
        "Find my latest tax return",
        "Show me utility bills from last month",
        "Where's my car insurance policy?",
        "Find receipts from Home Depot this year",
        "Where's my internet bill from last month?"
      ]
    },
    {
      title: "Financial Analysis",
      examples: [
        "How much did I spend on healthcare in 2023?",
        "Compare my utility costs year over year",
        "What's my average monthly spending on groceries?",
        "What was my total medical spending last quarter?",
        "Compare my utility bills for the past 6 months"
      ]
    },
    {
      title: "Document Summary",
      examples: [
        "Summarize my rental agreement",
        "What are the key points in my insurance policy?",
        "Give me the main terms of my employment contract",
        "What are my warranty deadlines?",
        "Explain my car loan terms"
      ]
    }
  ];

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await getUserProfile();
        setProfile(response.data);
      } catch (error) {
        console.error('Error fetching profile:', error);
      }
    };

    fetchProfile();
  }, []);

  useEffect(() => {
    const resetChat = () => {
      setShowChat(false);
      setCurrentQuestion('');
      setChatId(null);
      setInitialResponse(null);
      setSelectedChat(null);
    };

    resetChat();
  }, []);

  useEffect(() => {
    console.log('isChatSidebarOpen:', isChatSidebarOpen);
  }, [isChatSidebarOpen]);

  const handleQuestionSubmit = async (question) => {
    if (!question.trim()) return;
    
    try {
      setIsLoading(true);
      const chatPayload = {
        content: question,
        sender: 'user',
        files: []
      };
      const result = await createChat(chatPayload);
      
      setShowChat(true);
      setChatId(result.chat.id);
      setInitialResponse(result.initialResponse);
      trackEvent('query_submitted', { 
        query_length: question.length,
        query_type: 'chat'
      });
    } catch (error) {
      console.error('Error creating chat:', error);
      trackEvent('query_error', {
        error_type: error.response?.data?.error || 'unknown_error'
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleChatSelect = (chat) => {
    setShowChat(true);
    setChatId(chat.id);
    setSelectedChat(chat);
  };

  const handleFileSearchClick = () => {
    if (isFileSearchOpen) {
      fileSearchRef.current?.setIsFilterPanelOpen(true);
      return;
    }
    
    setIsFileSearchOpen(true);
  };

  const handleChatHistoryClick = () => {
    console.log('Opening chat history, current state:', !isChatSidebarOpen);
    setIsChatSidebarOpen(!isChatSidebarOpen);
  };

  const handleTemplateClick = (templateText) => {
    setCurrentQuestion(templateText);
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleFileUpload = async (files) => {
    try {
      setIsUploadModalOpen(true);
      trackEvent('document_upload', { 
        file_count: files.length,
        file_types: files.map(f => f.type)
      });
    } catch (error) {
      trackEvent('document_upload_error', {
        error_type: error.response?.data?.error || 'unknown_error'
      });
    }
  };

  return (
    <div className="dashboard">
      <LeftMenu 
        onChatHistoryClick={handleChatHistoryClick}
        onFileSearchClick={handleFileSearchClick}
        onFileUpload={handleFileUpload}
        isFileSearchActive={isFileSearchOpen}
      />
      <div className="dashboard-content" style={{ marginLeft: "120px" }}>
        <div className="content-wrapper">
          <div className="main-content">
            <FileSearch 
              ref={fileSearchRef}
              isOpen={isFileSearchOpen}
              onClose={() => setIsFileSearchOpen(false)}
              style={{ display: isFileSearchOpen ? 'block' : 'none' }}
            />
            
            {!isFileSearchOpen && !showChat && (
              <>
                <h1 className="welcome-text">Welcome back {profile.first_name},</h1>
                <div className="chat-input-container">
                  <input
                    ref={inputRef}
                    type="text"
                    className="chat-input"
                    placeholder="Ask a question about your documents"
                    value={currentQuestion}
                    onChange={(e) => setCurrentQuestion(e.target.value)}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter' && currentQuestion.trim()) {
                        handleQuestionSubmit(currentQuestion);
                      }
                    }}
                  />
                  <button 
                    className="chat-button"
                    onClick={() => handleQuestionSubmit(currentQuestion)}
                    disabled={!currentQuestion.trim() || isLoading}
                  >
                    {isLoading ? (
                      <i className="fas fa-spinner fa-spin"></i>
                    ) : (
                      <i className="fas fa-paper-plane"></i>
                    )}{' '}
                    Ask AI
                  </button>
                </div>

                <div className="templates-grid">
                  {suggestionTemplates.map((section, index) => (
                    <div key={index} className="template-section">
                      <h2>{section.title}</h2>
                      <div className="template-items">
                        {section.examples.map((example, exampleIndex) => (
                          <button 
                            key={exampleIndex}
                            onClick={() => handleTemplateClick(example)}
                          >
                            {example}
                          </button>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}
            
            {!isFileSearchOpen && showChat && (
              <Query 
                chatId={selectedChat ? selectedChat.id : chatId}
                initialQuestion={currentQuestion}
                initialResponse={initialResponse}
                existingChat={selectedChat}
                key={selectedChat ? selectedChat.id : chatId}
              />
            )}
          </div>
        </div>
      </div>
      <ChatSidebar 
        isOpen={isChatSidebarOpen} 
        onClose={() => setIsChatSidebarOpen(false)} 
        onChatSelect={handleChatSelect}
        onFileSearchClose={() => setIsFileSearchOpen(false)}
      />
      <UploadModal 
        isOpen={isUploadModalOpen} 
        onClose={() => setIsUploadModalOpen(false)} 
      />
    </div>
  );
};

export default Dashboard;
